import styled from 'styled-components'

export const InputField = styled.div`
  width: 100%;
  direction: rtl;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: relative;
`

export const Inputbox = styled.textarea`
  resize: none;
  min-height: 6rem;
  line-height: 2rem;
  border: solid ${(props) => props.theme.colors.lightRed};
  border-radius: 1.25rem;
  outline: none;
  padding: 0.5rem;
`
