import React, { ReactElement, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FormDiv,
  Container,
  StyledButtons,
  InputSection,
  Info,
  SingleInfo,
} from './styled-components'
import Inputfield from '../../input/input-field'
import Button from '../../button'
import Text from '../../text'
import InputArea from '../../input/input-area'
import { Alert } from '../../../constants/sweet-alert'
import { sendEmail } from '../../../api/contact-us'
import { Email } from '../../../types/email'
import { DataTypeSchema, schema } from './config'
import Icon from '../../icon'

interface ResetPasswordProps {
  onGoBackClick: () => void
}

const ContactUsConnection = ({
  onGoBackClick,
}: ResetPasswordProps): ReactElement => {
  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<DataTypeSchema>({
    resolver: zodResolver(schema),
  })

  const onSubmit = (data: DataTypeSchema) => {
    setLoading(true)
    sendEmail(data as unknown as Email).then((res) => {
      if (res !== 200) {
        Alert.fire({
          icon: 'warning',
          title: `حصل خطأ ,الرجاء المحاولة لاحقا `,
        })
      } else {
        Alert.fire({
          icon: 'success',
          title: `تم إرسال الطلب بنجاح`,
        })
        reset()
      }
      setLoading(false)
    })
  }
  return (
    <Container>
      <Text center weight="bold">
        إتصل بنا
      </Text>
      <Info>
        <SingleInfo>
          <Text size="small"> 501 790 71 216+</Text>
          <Icon
            name="ph:phone-call-light"
            color="black"
            width={25}
            height={25}
          />
        </SingleInfo>
        <SingleInfo>
          <Text size="small">شارع يوغرطة ,تونس ,تونس</Text>
          <Icon
            name="ph:map-pin-line-light"
            color="black"
            width={25}
            height={25}
          />
        </SingleInfo>
      </Info>

      <FormDiv>
        <InputSection>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <Inputfield
                value={field.value}
                labelName="اللقب"
                onValueChange={field.onChange}
              />
            )}
          />

          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Inputfield
                value={field.value}
                labelName="الإسم"
                onValueChange={field.onChange}
              />
            )}
          />
        </InputSection>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Inputfield
              value={field.value}
              labelName="البريد الإلكتروني"
              onValueChange={field.onChange}
            />
          )}
        />
        <Controller
          name="subject"
          control={control}
          render={({ field }) => (
            <Inputfield
              value={field.value}
              labelName="الموضوع"
              onValueChange={field.onChange}
            />
          )}
        />
        <Controller
          name="text"
          control={control}
          render={({ field }) => (
            <InputArea
              value={field.value}
              labelName="نص الموضوع"
              onValueChange={field.onChange}
            />
          )}
        />
      </FormDiv>

      <StyledButtons>
        <Button
          loading={loading}
          onClick={
            Object.keys(errors).length === 0
              ? handleSubmit(onSubmit)
              : () =>
                  Alert.fire({
                    icon: 'warning',
                    title: `عليك التثبت من المعطيات`,
                  })
          }
          color="darkRed"
        >
          <Text>إرسال</Text>
        </Button>
        <Button color="white" onClick={() => onGoBackClick()}>
          <Text>الرجوع</Text>
        </Button>
      </StyledButtons>
    </Container>
  )
}

export default ContactUsConnection
