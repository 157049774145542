import React, { ReactElement } from 'react'
import { StyledImg, ComingSoonContainer, StyledLink } from './styled-components'
import Text from '../text'
import Button from '../button'
import ComingSoonImg from '../../assets/images/coming-soon.png'

function ComingSoon(): ReactElement {
  return (
    <>
      <ComingSoonContainer>
        <StyledImg src={ComingSoonImg} />
        <Text size="big">في طور الاعداد</Text>
        <StyledLink to="/dashboard">
          <Button size="100%" color="darkRed">
            <Text>العودة إلى الرئيسية</Text>
          </Button>
        </StyledLink>
      </ComingSoonContainer>
    </>
  )
}
export default ComingSoon
