import styled from 'styled-components'

export const CategoryCardMatrix = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, auto));
  grid-gap: 3rem;
  width: 100%;
  direction: rtl;
  justify-items: center;
  justify-content: center;
`
