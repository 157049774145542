/* eslint-disable no-octal */
const locationsOptions = [
  {
    id: 1,
    label: 'باجة',
    value: 1,
  },
  {
    id: 2,
    label: 'الكاف',
    value: 2,
  },
  {
    id: 3,
    label: 'جندوبة',
    value: 3,
  },
  {
    id: 4,
    label: 'بنزرت',
    value: 4,
  },
  {
    id: 5,
    label: 'تونس',
    value: 5,
  },
  {
    id: 6,
    label: 'بن عروس',
    value: 6,
  },
  {
    id: 7,
    label: 'منوبة',
    value: 7,
  },
  {
    id: 8,
    label: 'أريانة',
    value: 8,
  },
  {
    id: 9,
    label: 'نابل',
    value: 9,
  },
  {
    id: 10,
    label: 'زغوان',
    value: '10',
  },
  {
    id: 11,
    label: 'سليانة',
    value: '11',
  },
  {
    id: 12,
    label: 'القصرين',
    value: '12',
  },
  {
    id: 13,
    label: 'سوسة',
    value: '13',
  },
  {
    id: 14,
    label: 'المنستير',
    value: '14',
  },
  {
    id: 15,
    label: 'المهدية',
    value: '15',
  },
  {
    id: 16,
    label: 'القيروان',
    value: '16',
  },
  {
    id: 17,
    label: 'سيدي بوزيد',
    value: '17',
  },
  {
    id: 18,
    label: 'صفاقس',
    value: '18',
  },
  {
    id: 19,
    label: 'قابس',
    value: '19',
  },
  {
    id: 20,
    label: 'قفصة',
    value: '20',
  },
  {
    id: 21,
    label: 'توزر',
    value: '21',
  },
  {
    id: 22,
    label: 'قبلي',
    value: '22',
  },
  {
    id: 23,
    label: 'تطاوين',
    value: '23',
  },
  {
    id: 24,
    label: 'مدنين',
    value: '24',
  },
  {
    id: 25,
    label: 'المهجر',
    value: '25',
  },
  {
    id: 0,
    label: 'عضو قيادة عامة',
    value: 0,
  },
]

export default locationsOptions
