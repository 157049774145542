import styled from 'styled-components'

export const Matrix = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 100%;
  }
`
export const InputSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    flex-direction: column-reverse;
  }
`
export const Container = styled.div`
  display: flex;
  gap: 2rem;

  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 90%;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 90%;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
`
export const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 2rem;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    width: 100%;
  }
`
export const Info = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    gap: 0.75rem;
    justify-content: flex-end;
  }
`
export const SingleInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
