import styled from 'styled-components'

export const RightSidePage = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  height: ${(props) => (props.responsiveMod ? 'calc(100vh - 10rem)' : '100vh')};
  width: 20rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  background: ${(props) => props.theme.gradientColors.red};
  padding: 1.5rem 0;
  right: 0;
  box-sizing: border-box;
  top: 0;
  overflow-y: auto;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;

  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    height: calc(100% - 10rem);
    width: 80vw;
    top: 10rem;
    padding: 0;
    transform: ${(props) =>
      props.responsiveMod ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.8s;
    box-shadow: ${(props) =>
      props.responsiveMod ? 'rgb(255 0 0 / 50%) 0px 5px 15px' : ''};
  }
`
export const Bar = styled.div`
  width: 100%;
  height: 0.125rem;
  margin-top: 1.5rem;
  background-color: ${(props) => props.theme.colors.lightRed};
`

export const TopLeft = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 3.75rem;
  width: 3.125rem;
  height: 100%;
  margin-left: -1.75rem;
  position: absolute;
  top: 0;
  left: 0;
`

export const StyledMenu = styled.div`
  margin-top: 1.5rem;
  z-index: 1000;
`
export const StyledDetails = styled.div`
  display: flex;
`
export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    margin: 0.5rem 0;
  }
`
export const StyledSideBar = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.25rem;
`

export const Click = styled.div`
  width: 20vw;
  height: calc(100% - 10rem);
  position: absolute;
  left: 0;
  top: 10rem;
`
