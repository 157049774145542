import styled from 'styled-components'

export const ConnexionScreen = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  width: 100%;
  display: flex;
  position: ${(props) => (props.responsiveMod ? 'fixed' : '')};
`

export const LeftSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  min-height: 100%;
  padding: 1rem 0;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 100%;
  }
`
export const StyledTitle = styled.div`
  text-align: center;
`
export const StyledSubTitle = styled.div`
  text-align: center;
`
export const StyledText = styled.div`
  display: flex;
  justify-content: flex-start;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    margin: 1rem;
  }
`

export const Section = styled.div`
  width: 100%;
  padding: 0 10rem;

  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    padding: 0 6rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    padding: 0 4rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    padding: 0;
  }
`

export const UnderlineSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
`

export const StyledImage = styled.img`
  width: 17rem;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;

  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 13rem;
  }
  @media (min-width: ${(props) => props.theme.responsive.wideScreen}) {
    width: 30rem;
    height: 20rem;
  }
`

export const StyledImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white25};
  backdrop-filter: blur(6.25rem);
  border-radius: 40px;
  box-shadow: ${(props) => props.theme.boxShadow.shadow2};
  padding: 0.5rem 4rem;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 15rem;
    height: 13rem;
  }
  @media (min-width: ${(props) => props.theme.responsive.wideScreen}) {
    width: 35rem;
    height: 25rem;
  }
`
export const StyledDivFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (min-width: ${(props) => props.theme.responsive.wideScreen}) {
    margin-top: 5rem;
  }
`

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`
export const StyledImg = styled.img`
  width: 9rem;
  height: 9rem;
  object-fit: contain;
`
export const Bar = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    justify-content: space-around;
  }
`
export const StyledIcon = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  display: none;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.colors.red};
    cursor: pointer;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    border-radius: 20px;
  }
`
export const RightSection = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  height: ${(props) => (props.responsiveMod ? 'calc(100vh - 10rem)' : '100vh')};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  box-sizing: border-box;
  background: ${(props) => props.theme.gradientColors.red};
  gap: 1.5rem;
  padding: 2rem;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  border-radius: 20px 0 0 20px;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    height: calc(100% - 10rem);
    width: 85vw;
    gap: 1rem;
    top: 10rem;
    padding: 1rem 0;
    transform: ${(props) =>
      props.responsiveMod ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.8s;
    box-shadow: ${(props) =>
      props.responsiveMod ? 'rgb(255 0 0 / 50%) 0px 5px 15px' : ''};
  }
`
export const StyledChildren = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  gap: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    filter: ${(props) => (props.responsiveMod ? 'blur(3px)' : '')};
    width: 70%;
  }
`
