import styled from 'styled-components'

export const DashboardCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  justify-items: center;
  justify-content: center;
  box-sizing: border-box;
  direction: rtl;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    direction: ltr;
  }
`
export const StyledImg = styled.img`
  width: 7rem;
  height: 7rem;
  object-fit: contain;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 5rem;
    height: 5rem;
  }
`

export const StyledCard = styled.a`
  direction: rtl;
  width: 100%;
  height: 9rem;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.boxShadow.shadow1};
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    height: 7rem;
  }
`
