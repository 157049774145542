/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { DefaultTheme } from 'styled-components'
import {
  EmailSection,
  TextSection,
  StyledLink,
  StyledImg,
} from '../styled-components'
import Text from '../../../text'
import Button from '../../../button'
import successEmailVerif from '../../../../assets/images/success-email-verification.png'

export interface Props {
  color: keyof DefaultTheme['colors']
}

function SuccessEmailVerification({ color }: Props): ReactElement {
  return (
    <EmailSection>
      <StyledImg color={color} src={successEmailVerif} />
      <TextSection>
        <Text size="medium" center>
          تم التحقق من عنوان البريد الإلكتروني الخاص بك
        </Text>
      </TextSection>
      <StyledLink to="/">
        <Button size="100%" color="darkRed">
          <Text weight="bold">تسجيل الدخول</Text>
        </Button>
      </StyledLink>
    </EmailSection>
  )
}

export default SuccessEmailVerification
