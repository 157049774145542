import styled from 'styled-components'

export const StyledDetails = styled.div`
  padding: 1rem 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const ProfileDrop = styled.div``

export const StyledIcon = styled.div.attrs(
  (props: { responsiveMod: boolean; isOpen: boolean }) => props
)`
  transition: all 0.75s;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg) ' : 'rotate(0)')};
  display: ${(props) => (props.responsiveMod ? 'flex' : 'none')};
`

export const StyledName = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  cursor: ${(props) => (props.responsiveMod ? 'pointer' : '')};
  justify-content: ${(props) =>
    props.responsiveMod ? 'space-between' : 'center'};

  width: 100%;
  padding: 0.75rem;
  background-color: ${(props) => props.theme.colors.white25};
  border-radius: 15px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
`
export const ProfileDetails = styled.div`
  width: 100%;
  flex-direction: column;
  direction: rtl;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 1rem 0.5rem 0rem;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    margin-bottom: 0;
  }
`
export const StyledNameEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
