import React, { ReactElement, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { UnderlineSection, StyledText } from './styled-components'

import Login from '../../components/connexion/login'
import Signin from '../../components/connexion/signin'
import ResetPassword from '../../components/connexion/reset-password'
import UnderlineText from '../../components/Underline-text'
import HomeTemplate from '../../components/base-template/home'
import ContactUsConnection from '../../components/connexion/contact-us'

function Connexion(): ReactElement {
  const [renderpage, setrenderpage] = useState<
    'login' | 'signIn' | 'resetPassword' | 'contactUs'
  >('login')

  const connectedUser = localStorage.token ? localStorage.token : undefined

  if (connectedUser) {
    return <Navigate to="/dashboard" />
  }

  return (
    <HomeTemplate>
      {renderpage !== 'resetPassword' && renderpage !== 'contactUs' ? (
        <UnderlineSection>
          <UnderlineText
            onClick={() => {
              setrenderpage('signIn')
            }}
            color={renderpage === 'signIn' ? 'red' : 'lightRed'}
            value="إنشاء حساب"
          />
          <UnderlineText
            onClick={() => {
              setrenderpage('login')
            }}
            color={renderpage === 'login' ? 'red' : 'lightRed'}
            value="تسجيل الدخول"
          />
        </UnderlineSection>
      ) : (
        <></>
      )}
      {renderpage === 'login' ? (
        <Login
          onForgotPasswordClick={() => {
            setrenderpage('resetPassword')
          }}
        />
      ) : renderpage === 'signIn' ? (
        <Signin />
      ) : renderpage === 'resetPassword' ? (
        <ResetPassword
          onGoBackClick={() => {
            setrenderpage('login')
          }}
        />
      ) : (
        <ContactUsConnection
          onGoBackClick={() => {
            setrenderpage('login')
          }}
        />
      )}
      {renderpage !== 'resetPassword' && renderpage !== 'contactUs' ? (
        <div
          onClick={() => {
            setrenderpage('contactUs')
          }}
        >
          <StyledText size="small">إتصل بنا</StyledText>
        </div>
      ) : (
        <></>
      )}
    </HomeTemplate>
  )
}
export default Connexion
