import React, { ReactElement } from 'react'
import parse from 'html-react-parser'
import { Matrix, TextZone } from './styled-components'
import DashboardTemplate from '../../components/base-template/dashboard'
import TrainingCardMatrix from '../../components/training/training-card-matrix'
import { TrainingGroup } from '../../types/training'

interface TrainingGroupProps {
  tariningGroup: TrainingGroup
}

const TrainingGroupScreen = ({
  tariningGroup,
}: TrainingGroupProps): ReactElement => (
  <DashboardTemplate title={tariningGroup.title} active="primary-training">
    {
      <>
        <TextZone> {parse(tariningGroup.text)} </TextZone>
        <Matrix>
          <TrainingCardMatrix
            type={tariningGroup.name}
            trainings={tariningGroup?.trainings}
          />
        </Matrix>
      </>
    }
  </DashboardTemplate>
)
export default TrainingGroupScreen
