/* eslint-disable import/no-unused-modules */

export default {
  name: 'Default theme',
  colors: {
    white: '#fff',
    white25: 'rgba(255, 255, 255, 0.25)',
    black: '#353535',
    darkRed: '#D81E33',
    red: '#FF0320',
    lightRed: '#FFCFDA',
    green: '#04DB0C',
    orange: '#FD8618',
    darkGrey: '#8E8E8E',
    grey: '#C4C4C4',
    transparent: 'transparent',
  },
  typography: {
    fontSizes: {
      biggest: '3rem',
      veryBig: '1.75rem',
      big: '1.5rem',
      medium: '1.25rem',
      small: '1rem',
      verySmall: '0.8rem',
    },
    fontFamilies: {
      ElMessiriBold: 'ElMessiri-Bold',
      ElMessiriMedium: 'ElMessiri-Medium',
      ElMessiriRegular: 'ElMessiri-Regular',
      ElMessiriSemiBold: 'ElMessiri-SemiBold',
    },
    fontWeights: {
      bold: 'bold',
      regular: 'normal',
    },
  },
  backgroundColors: {
    red: '#FEF3F7',
    green: '#F1F8F1',
    orange: '#FFFFF3',
  },
  gradientColors: {
    red: 'linear-gradient(270.13deg, #D81E33 38.36%, #FF0320 89.11%)',
  },
  boxShadow: {
    shadow1:
      '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)',
    shadow2: '0px 50px 100px rgba(0, 0, 0, 0.25)',
    shadow3: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
  },
  responsive: {
    mobileS: '320px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopM: '1200px',
    laptopL: '1440px',
    wideScreen: '1920px',
  },
}
