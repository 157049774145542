import styled from 'styled-components'

export const CategoryCard = styled.div.attrs(
  (props: { status: 'finished' | 'pending' | null }) => props
)`
  border: 1px solid
    ${(props) =>
      props.status === 'finished'
        ? props.theme.colors.green
        : props.status === 'pending'
        ? props.theme.colors.orange
        : props.theme.colors.grey};

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 2.25rem;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  width: 20rem;
  min-height: 15rem;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    width: 16rem;
  }
`

export const StyledIcon = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
  box-sizing: border-box;
`
