/* eslint-disable max-len */
import React, { ReactElement, useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import Text from '../../../text'
import Icon from '../../../icon'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import LogoKachefa from '../../../../assets/images/LogoKachefa.png'

import {
  StyledDetails,
  ProfileDetails as StyledProfileDetails,
  StyledName,
  ProfileDrop,
  StyledIcon,
  StyledNameEdit,
} from './styled-components'
import { User } from '../../../../types/user'

export interface ProfileDetailsProps {
  user: User | undefined
}
function ProfileDetails({ user }: ProfileDetailsProps): ReactElement {
  const [responsiveMod, setResponsiveMod] = useState(false)
  const { width } = useWindowDimensions()
  const [isOpen, setIsOpen] = useState(true)
  const responsiveDimension = parseInt('768px'.replace('px', ''), 10)
  useEffect(() => {
    if (width && width < responsiveDimension) {
      setIsOpen(false)
      setResponsiveMod(true)
    } else {
      setIsOpen(true)
      setResponsiveMod(false)
    }
  }, [width, responsiveDimension])

  if (user) {
    return (
      <StyledProfileDetails>
        <div onClick={responsiveMod ? () => setIsOpen(!isOpen) : undefined}>
          <StyledName responsiveMod={responsiveMod}>
            <StyledNameEdit>
              <Text size="medium" color="white" weight="bold">
                {user.firstName} {user.lastName}
              </Text>
              <Link to="/profile">
                <Icon
                  name="akar-icons:edit"
                  color="white"
                  width={25}
                  height={25}
                />
              </Link>
            </StyledNameEdit>
            <StyledIcon responsiveMod={responsiveMod} isOpen={isOpen}>
              <Icon name="fe:arrow-down" color="white" width={30} height={30} />
            </StyledIcon>
          </StyledName>
        </div>

        <Fade
          distance="20px"
          duration={800}
          top
          collapse
          when={responsiveMod ? isOpen : undefined}
        >
          <ProfileDrop>
            <StyledDetails>
              <Icon
                name="fluent:mail-48-regular"
                color="white"
                width={30}
                height={30}
              />
              <Text lineHeight={0} size="small" color="white">
                {user.email}
              </Text>
            </StyledDetails>
            {user.idKachefa ? (
              <StyledDetails>
                <img src={LogoKachefa} />
                <Text
                  lineHeight={0}
                  letterSpacing={1.5}
                  size="small"
                  color="white"
                >
                  {user.idKachefa}
                </Text>
              </StyledDetails>
            ) : null}
            <StyledDetails>
              <Icon
                name="ph:phone-call-light"
                color="white"
                width={30}
                height={30}
                rotate="270deg"
              />
              <Text letterSpacing={1} lineHeight={0} size="small" color="white">
                {user.phone}
              </Text>
            </StyledDetails>
          </ProfileDrop>
        </Fade>
      </StyledProfileDetails>
    )
  }
  return <></>
}

export default ProfileDetails
