import styled from 'styled-components'

export const InputField = styled.div`
  width: 100%;
  direction: rtl;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: relative;
`

export const Inputbox = styled.input`
  border: solid ${(props) => props.theme.colors.lightRed};
  border-radius: 1.25rem;
  outline: none;
  padding: 0.5rem;
`

export const ShowhidePassword = styled.div`
  position: absolute;
  left: 1rem;
  top: 2.9rem;
  cursor: pointer;
`
