/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React, { ReactElement, useEffect, useState } from 'react'
import {
  DashboardCard as StyledDashboardCard,
  StyledImg,
  StyledCard,
} from './styled-components'
import { useConnectedUserContext } from '../../contexts/connected-user'
import certificate from '../../assets/images/certificate-dashboard.png'
import profile from '../../assets/images/profile-dashboard.png'
import contact from '../../assets/images/contactUs.png'
import Text from '../text'
import { TrainingGroup } from '../../types/training'
import { getTrainingGroups } from '../../api/training-group'

function DashboardCard(): ReactElement {
  const { connectedUser } = useConnectedUserContext()
  const [trainingsGroups, setTrainingsGroups] = useState<TrainingGroup[]>()
  useEffect(() => {
    getTrainingGroups().then((data) => {
      setTrainingsGroups(data)
    })
  }, [])

  return (
    <StyledDashboardCard>
      {trainingsGroups?.map((trainingsGroup) =>
        connectedUser?.idKachefa || trainingsGroup.isPublic ? (
          <StyledCard
            key={trainingsGroup.id}
            href={`/trainings?type=${trainingsGroup.name}`}
          >
            <Text> {trainingsGroup.title} </Text>
            <StyledImg src={trainingsGroup.imageUrl} />
          </StyledCard>
        ) : null
      )}

      <StyledCard href="/certifications">
        <Text>الشهائد المتحصل عليها</Text>
        <StyledImg src={certificate} />
      </StyledCard>

      <StyledCard href="/profile">
        <Text>تغيير معطيات الحساب</Text>
        <StyledImg src={profile} />
      </StyledCard>
      <StyledCard href="/contact-us">
        <Text>إتصل بنا</Text>
        <StyledImg src={contact} />
      </StyledCard>
    </StyledDashboardCard>
  )
}

export default DashboardCard
