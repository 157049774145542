/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { map } from 'lodash'
import { TrainingCardMatrix as StyledTrainingCardMatrix } from './styled-components'
import { Training } from '../../../types/training'
import TrainingCard from '../training-card'

export interface TrainingCardMatrixProps {
  trainings: Training[]
  type: string
}
function TrainingCardMatrix({
  trainings,
  type,
}: TrainingCardMatrixProps): ReactElement {
  return (
    <StyledTrainingCardMatrix>
      {map(trainings, (training: Training) => (
        <TrainingCard type={type} key={training.id} training={training} />
      ))}
    </StyledTrainingCardMatrix>
  )
}

export default TrainingCardMatrix
