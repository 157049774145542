import React, { ReactElement } from 'react'
import DashboardTemplate from '../../components/base-template/dashboard'
import DashboardCard from '../../components/dashboard-cards'
import NotificationMessage from '../../components/certifDownloadStatus'
import { DashboardCard as StyledDashboardCard } from './styled-components'
import Text from '../../components/text'

const DashboardScreen = (): ReactElement => (
  <>
    <DashboardTemplate title="الرئيسية" active="home">
      {localStorage.getItem('verifyInfo') !== 'true' ? (
        <NotificationMessage
          message={
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                localStorage.setItem('verifyInfo', 'true')
                window.location.replace('/profile')
              }}
            >
              <Text>يرجى التحقق من صحة معلوماتك الشخصية من خلال النقر هنا</Text>
            </div>
          }
          variant={'warning'}
        />
      ) : (
        <></>
      )}
      <StyledDashboardCard>
        <DashboardCard />
      </StyledDashboardCard>
    </DashboardTemplate>
  </>
)

export default DashboardScreen
