import axios from 'axios'
import axiosRequest from '../constants/axios-request'

export const emailVerification = (
  activateToken: string | undefined,
  action: 'delete' | 'activate'
): Promise<number> =>
  axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/auth/activate?action=${action}`,
    headers: { 'x-access-token': `${activateToken}` },
  })
    .then((result) => result.status)
    .catch((error) => error.response.status)

export const resendVerification = (email: string): Promise<number> =>
  axiosRequest
    .post(`${process.env.REACT_APP_API_URL}/auth/resendverification`, { email })
    .then((result) => result.status)
    .catch((error) => error.response.status)
