import styled from 'styled-components'

export const PartMatrix = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  width: 100%;

  .swiper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: auto !important;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
      width: 100% !important;
      padding: 0 34px;
      box-sizing: border-box;
    }
  }
  .mySwiper {
    position: unset;
    width: 85%;
    @media (max-width: ${(props) => props.theme.responsive.laptop}) {
      width: 70%;
    }
    @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
      width: 100%;
    }
  }
  .swiper-wrapper {
    @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
      height: var(--swiper-navigation-size) !important;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    width: auto;
    height: auto;
    background: ${(props) => props.theme.colors.red};
    box-sizing: border-box;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-top: calc(-10px - (var(--swiper-navigation-size) / 2)) !important;

    @media (max-width: ${(props) => props.theme.responsive.tablet}) {
      margin-top: calc(
        -0.75rem - (var(--swiper-navigation-size) / 2)
      ) !important;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 0.75rem;
    color: white;
    font-weight: bold;
  }
  .swiper-pagination {
    position: unset !important;
    width: 100%;
    margin-top: 1rem;
  }
  .swiper-pagination span {
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${(props) => props.theme.colors.darkRed};
    font-family: 'ElMessiri';
    color: ${(props) => props.theme.colors.white};
  }
`
