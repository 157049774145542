/* eslint-disable import/no-unused-modules */
import humps from 'humps'
import axios from 'axios'

const token = localStorage.getItem('token') || ''

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'x-access-token': `${token}`,
  },
})

axiosRequest.interceptors.response.use((response) => ({
  ...response,
  data: humps.camelizeKeys(response.data),
}))

export default axiosRequest
