import React, { ReactElement } from 'react'
import './App.css'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import theme from '../theme'
import DashboardScreen from '../screens/dashboard'
import GetCertificateScreen from '../screens/get-certificate-screen'
import Connexion from '../screens/connexion'
import ResetPassword from '../screens/connexion/reset-password'
import EmailVerificationScreen from '../screens/connexion/email-verification'
import ProtectedRoutes from '../screens/ProtectedRoutes'
import Certification from '../screens/certifications'
import { ConnectedUserProvider } from '../contexts/connected-user'
import Profile from '../screens/profile'
import NotFound from '../screens/not-found'
import ContactUs from '../screens/contact-us'
import ProtectedRoutesTraining from '../screens/ProtectedRoutesTraining'

function App(): ReactElement {
  return (
    <ConnectedUserProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Connexion />} />
            <Route path="/reset/:resetToken" element={<ResetPassword />} />
            <Route
              path="/verify/:activateToken"
              element={<EmailVerificationScreen />}
            />
            <Route element={<ProtectedRoutes />}>
              <Route path="/dashboard" element={<DashboardScreen />} />
              <Route path="/certifications" element={<Certification />} />
              <Route
                path="/getcertificate/:trainingId"
                element={<GetCertificateScreen />}
              />
              <Route element={<ProtectedRoutesTraining />} path="/trainings" />
              <Route path="/profile" element={<Profile />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ConnectedUserProvider>
  )
}
export default App
