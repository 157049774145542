import styled from 'styled-components'

export const TrainingCard = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey};

  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  width: 100%;
  height: 6.25rem;
  border-radius: 20px;

  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    height: 5rem;
  }
`

export const StyledText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;
  box-sizing: border-box;
`
export const Code = styled.div.attrs((props: { border: boolean }) => props)`
  display: ${(props) => (props.border ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${(props) => props.theme.colors.grey};
  height: 100%;
  width: 15%;
`
export const StyledLinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  height: 100%;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    padding: 0 1.5rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    padding: 0 0.75rem;
  }
`
