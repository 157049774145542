import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { emailVerification } from '../../../api/email-verification'

import HomeTemplate from '../../../components/base-template/home'
import SuccessEmailVerification from '../../../components/connexion/email-verification/success-email-verification'
import ErrorEmailVerification from '../../../components/connexion/email-verification/error-email-verification'
import Loading from '../../../components/loading'

const EmailVerificationScreen = (): ReactElement => {
  const { activateToken } = useParams<string>()
  const [queryParams] = useSearchParams()
  const action = queryParams.get('action')

  const [isActivate, setIsActivate] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (action === 'activate') {
      emailVerification(activateToken, action).then((status) => {
        localStorage.removeItem('token')

        if (status === 200) {
          setIsActivate(true)
        } else {
          setIsActivate(false)
        }
      })
    }
  }, [action, activateToken])

  return (
    <>
      {isActivate === undefined ? (
        <Loading />
      ) : (
        <HomeTemplate>
          {isActivate ? (
            <SuccessEmailVerification color="green" />
          ) : (
            <ErrorEmailVerification color="red" />
          )}
        </HomeTemplate>
      )}
    </>
  )
}

export default EmailVerificationScreen
