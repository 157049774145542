import React, { ReactElement, ChangeEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ButtonContent } from './styled-components'
import InputField from '../../../components/input/input-field'
import { Alert } from '../../../constants/sweet-alert'
import Text from '../../../components/text'
import Button from '../../../components/button'
import { resetPasswordSet } from '../../../api/user'
import HomeTemplate from '../../../components/base-template/home'

function ResetPassword(): ReactElement {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { resetToken } = useParams<string>()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const onResetClick = () => {
    setLoading(true)

    if (password === confirmPassword && password.length > 7 && !loading) {
      resetPasswordSet(resetToken, password).then((status) => {
        if (status) {
          navigate('/')
          setLoading(false)
          Alert.fire({
            icon: 'success',
            title: 'تم إعادة تعيين كلمة المرور الخاصة بك بنجاح',
          })
        } else {
          setLoading(false)

          Alert.fire({
            icon: 'error',
            title: 'يرجى إعادة محاولة طلب إعادة تعيين كلمة المرور',
          })
        }
      })
    } else {
      setLoading(false)

      Alert.fire({
        icon: 'warning',
        title: 'الرجاء التثبت من كلمة السر (8 أحرف على الأقل) ',
      })
    }
  }

  return (
    <HomeTemplate>
      <Text>إعادة تعيين كلمة السر</Text>
      <InputField
        value={password}
        labelName="كلمة السر"
        type="password"
        onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
          setPassword(event.target.value)
        }
        onKeyPress={onResetClick}
      />
      <InputField
        value={confirmPassword}
        labelName="تأكيد كلمة السر "
        type="password"
        onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
          setConfirmPassword(event.target.value)
        }
        onKeyPress={onResetClick}
      />
      <ButtonContent>
        <Button
          size="100%"
          onClick={onResetClick}
          color="darkRed"
          loading={loading}
        >
          <Text color="white">إعادة تعيين كلمة السر</Text>
        </Button>
        <Button
          size="100%"
          color="white"
          onClick={() => navigate('/dashboard')}
        >
          <Text>العودة الى الرئيسية</Text>
        </Button>
      </ButtonContent>
    </HomeTemplate>
  )
}
export default ResetPassword
