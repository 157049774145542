/* eslint-disable react-hooks/exhaustive-deps */

import React, { ReactElement, useEffect, useState } from 'react'
import moment from 'moment'
import { map, isEmpty } from 'lodash'
import Fade from 'react-reveal/Fade'
import {
  CertificationCard as StyledCertificationCard,
  StyledButton,
  RightDropSection,
  CertificationCardMatrix as StyledCertificationCardMatrix,
  TrainingsDrop,
  DropDownIcon,
  StyledImage,
  EmptyCertifCard,
  CertificationsContent,
  StyledText,
  FadeDiv,
} from './styled-components'
import { Certification } from '../../types/certification'
import Text from '../text'
import Icon from '../icon'
import Button from '../button'
import { useConnectedUserContext } from '../../contexts/connected-user'
import DownloadPdf from '../../constants/pdf-generator/download-pdf'
import { User } from '../../types/user'
import { TrainingGroup } from '../../types/training'

interface CertificationCardProps {
  trainingsGroup: TrainingGroup
  DownloadStatus: (
    text: string,
    status: 'success' | 'pending' | 'error'
  ) => void
}
const CertificationCard = ({
  DownloadStatus,
  trainingsGroup,
}: CertificationCardProps): ReactElement => {
  const { connectedUser } = useConnectedUserContext()

  const [certifications, setCertifications] = useState<Certification[]>([])
  const [showCertifications, setShowCertifications] = useState(false)

  useEffect(() => {
    connectedUser?.certifications.map((certification) => {
      const findCertification = trainingsGroup.trainings.find(
        (e) => (e as unknown as string) === certification.training.id
      )

      if (findCertification) {
        const oldCertification = certifications
        oldCertification.push(certification)
        setCertifications(oldCertification)
      }
      return null
    })
  }, [])

  return (
    <StyledCertificationCardMatrix key={trainingsGroup.id}>
      <TrainingsDrop>
        <RightDropSection>
          <DropDownIcon
            onClick={() => setShowCertifications(!showCertifications)}
            isOpen={showCertifications}
          >
            <Icon name="fe:arrow-down" color="grey" width={30} height={30} />
          </DropDownIcon>
          <StyledText> {trainingsGroup.title} </StyledText>
        </RightDropSection>
        <StyledImage src={trainingsGroup.imageUrl} />
      </TrainingsDrop>
      <CertificationsContent>
        <Fade distance="20px" top when={showCertifications}>
          <FadeDiv showCertifications={showCertifications}>
            {isEmpty(certifications) ? (
              <EmptyCertifCard>
                <Text size="medium">لم تتحصل على شهادة بعد</Text>
              </EmptyCertifCard>
            ) : (
              map(certifications, (certification: Certification) => (
                <StyledCertificationCard
                  onClick={() => {
                    DownloadPdf(
                      DownloadStatus,
                      certification.id,
                      connectedUser as User
                    )
                  }}
                  key={certification.id}
                >
                  <Text color="grey">
                    {moment(certification.date).format('L')}
                  </Text>
                  <Text>{certification.training.title}</Text>
                  <StyledButton>
                    <Button color="darkRed">
                      <Icon
                        name="bi:arrow-left"
                        color="white"
                        width={30}
                        height={30}
                      />
                    </Button>
                  </StyledButton>
                </StyledCertificationCard>
              ))
            )}
          </FadeDiv>
        </Fade>
      </CertificationsContent>
    </StyledCertificationCardMatrix>
  )
}

export default CertificationCard
