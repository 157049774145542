import styled from 'styled-components'
import Text from '../../text'

export const Tickbox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`
export const StyledText = styled(Text)`
  width: 90%;
`
export const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 22px;
  width: 22px;
  transition: all 0.15s ease-out 0s;
  color: ${(props) => props.theme.colors.white};
  border: 1px solid red;
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  border-radius: 4px;
  &:hover {
    background: ${(props) => props.theme.colors.lightRed};
  }
  &:checked {
    background: ${(props) => props.theme.colors.red};
  }
  &:checked::before {
    display: flex;
    content: '✓';
    font-size: 0.825rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
  }
`
