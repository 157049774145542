/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-unused-modules */
import React, { ReactElement, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Text from '../../../components/text'
import home from '../../../assets/images/home.png'

import {
  ConnexionScreen,
  RightSection,
  LeftSection,
  Section,
  StyledImage,
  StyledImageBox,
  StyledTitle,
  StyledSubTitle,
  StyledText,
  StyledDivFlex,
  StyledImg,
  Bar,
  StyledIcon,
  StyledChildren,
} from './styled-components'
import logo from '../../../assets/images/logo.png'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Icon from '../../icon'

export interface HomeTemplateProps {
  children: ReactElement[] | ReactElement
}
function HomeTemplate({ children }: HomeTemplateProps): ReactElement {
  const [responsiveMod, setResponsiveMod] = useState(false)
  const [WideResponsiveMod, setWideResponsiveMod] = useState(false)
  const { width } = useWindowDimensions()
  const navigate = useNavigate()

  useEffect(() => {
    if (width && width > 768) {
      setResponsiveMod(false)
    }
  }, [width])
  useEffect(() => {
    if (width && width > 1920) {
      setWideResponsiveMod(true)
    }
  }, [width])

  useEffect(() => {
    if (responsiveMod) {
      history.pushState(null, '', location.href)
      window.onpopstate = () => {
        setResponsiveMod(false)
      }
    } else {
      window.onpopstate = () => {
        navigate(-1)
      }
    }
  })

  return (
    <ConnexionScreen responsiveMod={responsiveMod}>
      <LeftSection>
        <Section>
          <Bar responsiveMod={responsiveMod}>
            <StyledImg src={logo} alt="logo" />
            <StyledIcon
              responsiveMod={responsiveMod}
              onClick={() => setResponsiveMod(!responsiveMod)}
            >
              <Icon
                name={
                  responsiveMod ? 'clarity:home-line' : 'eva:bar-chart-fill'
                }
                rotate={responsiveMod ? '' : '270deg'}
                color="darkRed"
                width={20}
                height={20}
              />
            </StyledIcon>
          </Bar>
          <StyledChildren responsiveMod={responsiveMod}>
            {children}
          </StyledChildren>
        </Section>
      </LeftSection>
      <RightSection responsiveMod={responsiveMod}>
        <StyledTitle>
          <Text weight="bold" size="biggest" color="white">
            الكشافة التونسية
          </Text>
        </StyledTitle>
        <StyledSubTitle>
          <Text size="medium" color="white">
            اللجنة الوطنية لتننمية القيادات
          </Text>
        </StyledSubTitle>
        <StyledText>
          <Text
            lineHeight={WideResponsiveMod ? 50 : 24}
            textAlign="justify"
            size={WideResponsiveMod ? 'big' : 'verySmall'}
            color="white"
          >
            أصبح التدريب الافتراضي في السنوات الأخيرة جزءا أساسيا من المشهد
            التدريبي اليوم حيث أعطى شكلا جديدا للتعلم و لتدريب الراشدين. حيث
            يساعد هذا التوجه للوصول بسهولة إلى مجموعات عمل من جميع الأحجام
            والمواقع خاصة تلك المجموعات البعيدة و التي يتطلب الوصول إليها إعدادً
            لوجيستي و تنظيمي كبير. وقد اقتحمت اللجنة الوطنية لتنمية القيادات منذ
            فترة غمار التدريب عن بعد من خلال تنظيم بعض الدورات التدريبية
            الإفتراضية و التي نطمح لمزيد تعميمها في المستقبل. حيث أنه من خلال
            التدريب الافتراضي ، يمكن للقيادات بالكشافة التونسية ولغير المنتسبين
            للكشافة التونسية اكتساب المعارف والمهارات الجديدة بشكل أكثر كفاءة ،
            لا سيما في عالم سريع الخطى ، وذلك بجعل التدريب أكثر ملاءمة للدور ،
            بمساعدة التكنولوجيا الحديثة. نقدّم اليوم لكم المنصة التدريبية
            الإفتراضية للكشافة التونسية في إطار تنفيذ السياسة الوطنية لتنمية
            القيادات وخاصة أنظمة التأهيل القيادي، حيث تطرح هذه المنصة دورات
            تدريبية في إطار أنظمة التأهيل (التدريب الرسمي). وبتقديم دورات أخرى
            لمختلف المهام وتفتح دورات للكشافين لغير الكشافين لكي يساهم التدريب
            الكشفي في تحقيق هدفها عن طريق بناء قدرات الشباب (التدريب الوظيفي).
            فمرحبا بكم جميعا في : المنصة التدريبية الإفتراضية للكشافة التونسية.
          </Text>
        </StyledText>

        <StyledDivFlex>
          <StyledImageBox>
            <StyledImage src={home} alt="home" />
          </StyledImageBox>
        </StyledDivFlex>
      </RightSection>
    </ConnexionScreen>
  )
}

export default HomeTemplate
