import { Link } from 'react-router-dom'
import styled, { DefaultTheme } from 'styled-components'

export const EmailSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
`
export const TextSection = styled.div`
  width: 100%;
  direction: rtl;
  flex-direction: column;
  display: flex;
  gap: 1rem;
  justify-content: center;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`

export const StyledImg = styled.img.attrs(
  (props: { color: keyof DefaultTheme['colors'] }) => props
)`
  width: 10rem;
  padding: 1.5rem 4rem;
  box-shadow: ${(props) => props.theme.colors[props.color]} 0px 25px 20px -20px;
`
