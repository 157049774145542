import styled from 'styled-components'
import Text from '../../components/text'

export const UnderlineSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
`
export const StyledText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  &:hover::after {
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0.125rem;
    background: ${(props) => props.theme.colors.darkRed};
    transition: width 0.4s;
  }
`
