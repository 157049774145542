/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import {
  CategoryCard as StyledCategoryCard,
  StyledIcon,
} from './styled-components'
import { Category } from '../../../types/training'
import Text from '../../text'
import Icon from '../../icon'
import Number from '../../number'

export interface CategoryCardProps {
  category: Category
  onClick: (category: Category) => void
}
function CategoryCard({ category, onClick }: CategoryCardProps): ReactElement {
  return (
    <StyledCategoryCard
      onClick={() => onClick(category)}
      status={category.status}
    >
      <Number number={category.placement} />
      <Text center>{category.title}</Text>
      <StyledIcon>
        {category.status === 'finished' ? (
          <Icon
            name="bi:check-circle-fill"
            color="green"
            width={40}
            height={40}
          />
        ) : null}
        {category.status === 'pending' ? (
          <Icon
            name="carbon:pending-filled"
            color="orange"
            width={40}
            height={40}
          />
        ) : null}
      </StyledIcon>
    </StyledCategoryCard>
  )
}

export default CategoryCard
