/* eslint-disable import/no-unused-modules */

import styled from 'styled-components'
import Select from 'react-select'

export const Selection = styled(Select)`
  .Select__control {
    height: 36.8px;
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
    border-color: ${(props) => props.theme.colors.lightRed};
    border-width: 3px;
  }

  .Select__control:hover {
    border-color: ${(props) => props.theme.colors.lightRed};
  }

  .Select__control--is-focused {
    border: 1px solid ${(props) => props.theme.colors.red};
    box-shadow: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__option:hover {
    border-width: 3px;

    background-color: ${(props) => props.theme.colors.lightRed};
  }
  .Select__option--is-selected {
    background-color: ${(props) => props.theme.colors.red};
  }
  .Select__single-value {
    font-size: 1rem;
  }
  .Select__placeholder {
    font-size: 0.75rem;
  }
  .Select__value-container {
    margin-bottom: 4px;
  }
`
