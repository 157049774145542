/* eslint-disable import/no-unused-modules */
/* eslint func-names: ["error", "as-needed"] */

import React, { ReactElement } from 'react'
import { Icon as Iconify, IconifyIcon } from '@iconify/react'

import { DefaultTheme } from 'styled-components'
import theme from '../../theme'

interface IconProps {
  name: string | IconifyIcon
  width?: number
  height?: number
  color?: string
  rotate?: string
}

function Icon({ name, rotate, height, width, color }: IconProps): ReactElement {
  return (
    <i style={{ display: 'flex', alignItems: 'center' }}>
      <Iconify
        rotate={rotate}
        width={width}
        height={height}
        icon={name}
        color={theme.colors[color as keyof DefaultTheme['colors']] || color}
      />
    </i>
  )
}

export default Icon
