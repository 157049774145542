import { pdf } from '@react-pdf/renderer'
import React from 'react'
import { Certification } from '../../types/certification'
import MyDocument from '../../components/pdf-generator'
import { User } from '../../types/user'

const DownloadPdf = async (
  DownloadStatus: (
    text: string,
    status: 'success' | 'pending' | 'error'
  ) => void,
  certification: string | undefined,
  connectedUser: User | undefined
): Promise<string> => {
  const certificationVerify =
    connectedUser?.certifications.find(
      (element: Certification) => element.id === certification
    ) || null

  if (!certificationVerify) {
    DownloadStatus('حدث خطأ عند تحميل الشهادة، حاول مجددا لاحقا', 'error')
    return ''
  }
  DownloadStatus(
    `جارٍ تحميل شهادة ${certificationVerify?.training.title}`,
    'pending'
  )
  const blob = await pdf(
    <MyDocument
      connectedUser={connectedUser}
      certification={certificationVerify}
    />
  ).toBlob()
  const url = window.URL.createObjectURL(blob)
  if (!url) {
    DownloadStatus(`حدث خطأ  عند تحميل الشهادة، حاول مجددا لاحقا`, 'error')
    return ''
  }
  const a = document.createElement('a')
  a.href = url
  a.download = `${certificationVerify?.training.title} - ${connectedUser?.lastName} ${connectedUser?.firstName}.pdf`
  a.click()
  window.URL.revokeObjectURL(url)
  DownloadStatus(
    `تم تحميل شهادة ${certificationVerify?.training.title} بنجاح`,
    'success'
  )
  return ''
}

export default DownloadPdf
