import styled from 'styled-components'

export const PartContent = styled.div`
  width: 100%;
  height: 50vh;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.grey};
  overflow-y: auto;
  box-sizing: border-box;
  direction: rtl;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`

export const TextSection = styled.div`
  width: 100%;
`

export const PartScroll = styled.div`
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
`

export const UnfinishedPart = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
`
export const StyledImg = styled.img`
  width: 30%;
  object-fit: contain;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 16rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    width: 100%;
  }
`
