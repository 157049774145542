/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unused-modules */

import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  useContext,
  useState,
  useMemo,
} from 'react'
import { User } from '../types/user'

type ConnectedUserContextType = Partial<{
  connectedUser: User
}> & {
  setConnectedUser: Dispatch<User>
}

const connectedUserContext = createContext<ConnectedUserContextType>({
  setConnectedUser: (): void => {
    throw new Error('connectedUserContext not initialized')
  },
})

interface IconnectedUserProvider {
  children: ReactNode
}

export function ConnectedUserProvider({
  children,
}: IconnectedUserProvider): ReactElement {
  const [connectedUser, setConnectedUser] = useState<User>()

  const userDetails = useMemo(
    () => ({ connectedUser, setConnectedUser }),
    [connectedUser]
  )

  return (
    <connectedUserContext.Provider value={userDetails}>
      {children}
    </connectedUserContext.Provider>
  )
}

export const useConnectedUserContext = (): ConnectedUserContextType =>
  useContext(connectedUserContext)
