import styled from 'styled-components'
import Text from '../../text'

export const Radio = styled.div`
  width: 100%;
  direction: rtl;
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    flex-wrap: wrap;
  }
`
export const StyledText = styled(Text)`
  display: none;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    display: block;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    display: block;
  }
`
export const StyledTitle = styled(Text)`
  width: 70%;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 70%;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    width: 100%;
  }
`
export const Inputs = styled(Text)`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    margin-top: 1rem;
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 30%;
    margin-top: 0;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    margin-top: 1rem;
    width: 100%;
  }
`

export const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  height: 30px;
  width: 30px;
  transition: all 0.15s ease-out 0s;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  display: inline-block;
  outline: none;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.red};
  border-radius: 50%;

  &:hover {
    background: ${(props) => props.theme.colors.lightRed};
  }
  &:checked {
    background: ${(props) => props.theme.colors.red};
  }
  &:checked::before {
    width: 28px;
    height: 28px;
    display: flex;
    content: '✓';
    font-size: 1rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
  }
`
export const Element = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
