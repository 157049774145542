import styled from 'styled-components'
import Text from '../text'

export const CertificationCard = styled.a`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding: 1.25rem;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 0.25rem;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
`

export const StyledButton = styled.div`
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    display: none;
  }
`
export const CertificationCardMatrix = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 100%;
  }
`

export const StyledText = styled(Text)`
  border-right: 2px solid ${(props) => props.theme.colors.grey};
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 1.25rem;
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    padding-right: 0.5rem;
  }
`

export const TrainingsDrop = styled.div`
  width: 100%;
  height: 6rem;
  border: 2px solid ${(props) => props.theme.colors.grey};
  border-radius: 20px;
  display: flex;
  align-items: center;
  direction: rtl;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    height: 4.5rem;
  }
`

export const DropDownIcon = styled.div.attrs(
  (props: { isOpen: boolean }) => props
)`
  padding: 0 1.5rem;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.75s;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg) ' : 'rotate(0)')};
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    padding: 0 0.75rem;
  }
`
export const RightDropSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledImage = styled.img`
  height: 80%;
  object-fit: contain;
  margin-left: 1rem;
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    height: 60%;
  }
`
export const EmptyCertifCard = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey};
  display: flex;
  width: 100%;
  padding: 1.25rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`
export const CertificationsContent = styled.div`
  width: 100%;
`

export const FadeDiv = styled.div.attrs(
  (props: { showCertifications: boolean }) => props
)`
  display: ${(props) => (props.showCertifications ? 'block' : 'none')};
`

export const DownloadText = styled.div.attrs(
  (props: { downloadStatus: 'success' | 'pending' | 'error' }) => props
)`
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 50%;
  color: ${(props) =>
    props.downloadStatus === 'success'
      ? props.theme.colors.green
      : props.downloadStatus === 'pending'
      ? props.theme.colors.orange
      : props.theme.colors.red};
  border: 2px solid
    ${(props) =>
      props.downloadStatus === 'success'
        ? props.theme.colors.green
        : props.downloadStatus === 'pending'
        ? props.theme.colors.orange
        : props.theme.colors.red};
  border-color: ${(props) =>
    props.downloadStatus === 'success'
      ? props.theme.colors.green
      : props.downloadStatus === 'pending'
      ? props.theme.colors.orange
      : props.theme.colors.red};
  background-color: ${(props) =>
    props.downloadStatus === 'success'
      ? props.theme.backgroundColors.green
      : props.downloadStatus === 'pending'
      ? props.theme.backgroundColors.orange
      : props.theme.backgroundColors.red};
  border: 1px solid;
  border-bottom: 6px solid;

  @media (max-width: ${(props) => props.theme.responsive.laptopL}) {
    width: 80%;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    width: 100%;
  }
`
