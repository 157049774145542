/* eslint-disable import/no-unused-modules */

import React, { ReactElement, useEffect, useState } from 'react'
import moment from 'moment'
import { DefaultTheme } from 'styled-components'

import { EmailSection, TextSection, StyledImg } from '../styled-components'
import Text from '../../../text'
import Button from '../../../button'
import AlertEmailVerif from '../../../../assets/images/alert-email-verification.png'
import DashboardTemplate from '../../../base-template/dashboard'
import { Alert } from '../../../../constants/sweet-alert'
import { resendVerification } from '../../../../api/email-verification'
import { useConnectedUserContext } from '../../../../contexts/connected-user'

export interface Props {
  color: keyof DefaultTheme['colors']
}

function NeedVerification({ color }: Props): ReactElement {
  const [timer, setTimer] = useState<number>(0)
  const { connectedUser } = useConnectedUserContext()
  const currentTime = moment()
  const verifyTime = currentTime.diff(
    moment(localStorage.getItem('timer')),
    'second'
  )

  useEffect(() => {
    if (!Number.isNaN(verifyTime) && verifyTime < 300) {
      setTimer(300 - verifyTime)
    }
  }, [verifyTime])

  useEffect(() => {
    if (timer !== 0 && timer) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  const resendOnCliick = () => {
    if (timer === 0 && connectedUser) {
      localStorage.setItem('timer', moment().format())
      setTimer(300)
      resendVerification(connectedUser?.email).then((status) => {
        if (status === 200) {
          Alert.fire({
            icon: 'success',
            title: 'تم إرسال رسالة إعادة تفعيل الحساب',
          })
        } else {
          setTimer(0)
          localStorage.removeItem('timer')
          Alert.fire({
            icon: 'error',
            title: 'خطأ في السيرفر، يرجى المحاولة مرة أخرى في وقت لاحق',
          })
        }
      })
    } else {
      Alert.fire({
        icon: 'warning',
        title: 'الرجاء الانتظار',
      })
    }
  }

  return (
    <DashboardTemplate>
      <EmailSection>
        <StyledImg src={AlertEmailVerif} color={color} />
        <TextSection>
          <Text textAlign="justify">
            لقد تم ارسال رسالة تفعيل حسابكم على بريدكم الخاص, الرجاء التثبت من
            وجود البريد في جزء &apos;spam&apos;.
          </Text>
          <Text textAlign="justify">
            في صورة عدم التحصل على رسالة التفعيل يمكنكم طلب اعادة ارسال الرسالة
            عبر الضغط على الزر التالي
          </Text>
        </TextSection>
        <Button onClick={() => resendOnCliick()} size="70%" color="darkRed">
          {timer === 0 ? (
            <Text weight="bold">اعادة ارسال الطلب</Text>
          ) : (
            <Text weight="bold">
              {moment.utc(timer * 1000).format('mm:ss')}
            </Text>
          )}
        </Button>
      </EmailSection>
    </DashboardTemplate>
  )
}

export default NeedVerification
