import styled from 'styled-components'
import Confetti from 'react-confetti'

export const StyledImg = styled.img`
  width: 100%;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 80%;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    width: 100%;
  }
`

export const GetCertificateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`

export const Animation = styled(Confetti)`
  width: 100%;
  height: 100%;
`
