/* eslint-disable import/no-unused-modules */

import styled, { css, DefaultTheme } from 'styled-components'

interface TextProps {
  font?: keyof DefaultTheme['typography']['fontFamilies']
  color?: keyof DefaultTheme['colors']
  uppercase?: boolean
  size?: keyof DefaultTheme['typography']['fontSizes']
  weight?: keyof DefaultTheme['typography']['fontWeights']
  lineHeight?: number
  center?: boolean
  letterSpacing?: number
  textAlign?: string
}

const Text = styled.div<TextProps>`
  ${({
    font,
    color,
    uppercase,
    weight,
    size,
    theme,
    lineHeight,
    center,
    letterSpacing,
    textAlign,
  }) => css`
    display: inline;
    direction: rtl;
    color: ${
      color ? theme.colors[color as keyof DefaultTheme['colors']] || color : ''
    };
    text-align: ${center ? 'center' : null};
    letter-spacing: ${letterSpacing ? `${letterSpacing}px` : ''} ;
    text-align: ${textAlign || ''} ;
    text-transform: ${uppercase && 'uppercase'};
    font-family: ${theme.typography.fontFamilies[font || 'ElMessiriRegular']};
    font-size: ${theme.typography.fontSizes[size || 'medium']};
    font-weight: ${theme.typography.fontWeights[weight || 'regular']};
    line-height: ${
      lineHeight ? `${lineHeight}px` : theme.typography.fontSizes[size || 'big']
    };
    @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    font-size: ${
      size === 'small'
        ? theme.typography.fontSizes.verySmall
        : size === 'medium'
        ? theme.typography.fontSizes.small
        : size === 'big'
        ? theme.typography.fontSizes.small
        : size === 'veryBig'
        ? theme.typography.fontSizes.big
        : size === 'biggest'
        ? theme.typography.fontSizes.veryBig
        : theme.typography.fontSizes.verySmall
    };
    
  `}
`

export default Text
