import styled from 'styled-components'

export const StyledPart = styled.div.attrs(
  (props: { status: 'finished' | 'pending' | null }) => props
)`
  ${(props) =>
    props.status === 'finished'
      ? props.theme.colors.green
      : props.status === 'pending'
      ? props.theme.colors.orange
      : props.status === 'projected'
      ? props.theme.colors.white
      : null};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
