/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  RightSidePage as StyledRightSidePage,
  StyledMenu,
  StyledDetails,
  StyledButton,
  StyledSideBar,
  Click,
} from './styled-components'
import { User } from '../../../../types/user'
import ProfileDetails from '../profile-details'
import Menu from '../menu'
import Button from '../../../button'
import Text from '../../../text'

export interface RightSidePageProps {
  user: User | undefined
  active?: string
  responsiveMod: boolean
  onClickHide?: () => void
}

function RightSidePage({
  responsiveMod,
  user,
  active,
  onClickHide,
}: RightSidePageProps): ReactElement {
  const navigate = useNavigate()

  const logOut = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('redirectUrl')
    navigate('/')
  }

  return (
    <div>
      <StyledRightSidePage responsiveMod={responsiveMod}>
        <StyledSideBar>
          <div>
            <StyledDetails>
              <ProfileDetails user={user} />
            </StyledDetails>
            <StyledMenu>
              <Menu active={active} />
            </StyledMenu>
          </div>
          <StyledButton>
            <Button onClick={logOut} color="transparent" size="8rem">
              <Text size="small">تسجيل الخروج</Text>
            </Button>
          </StyledButton>
        </StyledSideBar>
      </StyledRightSidePage>
      {responsiveMod ? <Click onClick={onClickHide} /> : null}
    </div>
  )
}

export default RightSidePage
