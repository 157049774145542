/* eslint-disable react-hooks/exhaustive-deps */

import React, { ReactElement, useEffect, useState } from 'react'
import { Part, Question } from '../../types/training'
import { Quiz as StyledQuiz, Message, Response } from './styled-components'
import Text from '../../components/text'
import QuizSelect from './quiz-select/index'
import QuizTick from './quiz-tick/index'
import Button from '../button'
import { SelectItems } from '../../types/select'
import { checkAnswers } from '../../api/question'
import Icon from '../icon'
import QuizTrueFalse from './quiz-true-false'
import { Alert } from '../../constants/sweet-alert'

interface QuizProps {
  part: Part | undefined
  progStatus: 'finished' | 'pending' | null | undefined
  buttonSwitch: (value: boolean) => void
}
interface ResponseType {
  id: string
  answer: string[]
}

function Quiz({ part, buttonSwitch, progStatus }: QuizProps): ReactElement {
  const [answers, setAnswers] = useState<ResponseType[]>([])
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setMessage('')
    if (progStatus) {
      if (progStatus !== 'finished') {
        buttonSwitch(true)
      }
    }
    part?.questions.map((question) =>
      setAnswers((emptyAnswers) => [
        ...emptyAnswers,
        { id: question.id, answer: [] },
      ])
    )
  }, [part])

  const onResponseChange = (selectedItem: SelectItems, question: Question) => {
    if (typeof selectedItem.value === 'string') {
      setMessage('')
      const oldState = [...answers]
      const findQuestionIndex = answers.findIndex((el) => el.id === question.id)
      oldState[findQuestionIndex].answer = [selectedItem.value]
      setAnswers(oldState)
    }
  }
  const onMultiResponseChange = (
    selectedItem: SelectItems,
    question: Question
  ) => {
    if (typeof selectedItem.value === 'string') {
      setMessage('')

      const oldState = [...answers]
      const findQuestionIndex = answers.findIndex((el) => el.id === question.id)
      if (
        oldState[findQuestionIndex].answer?.indexOf(selectedItem.value) === -1
      ) {
        oldState[findQuestionIndex].answer?.push(selectedItem.value)
      } else {
        const arr = oldState[findQuestionIndex].answer
        arr?.splice(arr?.indexOf(selectedItem.value), 1)
      }

      setAnswers(oldState)
    }
  }

  // checking if there is empty answer
  const emptyCheck = async () => {
    let empty = false
    answers.forEach((elem) => {
      if (elem.answer.length === 0) {
        empty = true
      }
    })
    return empty
  }
  // sending answers to api to check if they are correct
  const check = () => {
    setLoading(true)
    emptyCheck().then((empty) => {
      if (!empty) {
        checkAnswers(answers).then((data) => {
          const response = data.result.toString()
          if (response === 'true') {
            buttonSwitch(false)
          }
          setMessage(response)
          setLoading(false)
        })
      } else {
        Alert.fire({
          icon: 'warning',
          title: ' الرجاء الإجابة على جميع الأسئلة ',
        })
        setLoading(false)
      }
    })
  }
  return (
    <StyledQuiz>
      <Text weight="bold" size="big">
        {part?.title}
      </Text>
      {part?.quizType === 'dropdown' ? (
        <QuizSelect
          part={part}
          onChange={(selectedItem, question) => {
            onResponseChange(selectedItem, question)
          }}
        />
      ) : part?.quizType === 'tickbox' ? (
        <QuizTick
          part={part}
          onValueChange={(selectedItem, question) => {
            onMultiResponseChange(selectedItem, question)
          }}
        />
      ) : (
        <>
          <Response>
            <Text> {part?.questions[0].answers[0]} </Text>
            <Text> {part?.questions[0].answers[1]} </Text>
          </Response>
          <QuizTrueFalse
            part={part}
            onValueChange={(selectedItem, question) => {
              onResponseChange(selectedItem, question)
            }}
          />
        </>
      )}

      {message === 'true' ? (
        <Message>
          <Icon name="bi:check-circle-fill" width={30} color={'green'}></Icon>
          <Text>الإجابات صحيحة</Text>
        </Message>
      ) : (
        <></>
      )}
      {message === 'false' ? (
        <Message>
          <Icon name="akar-icons:circle-x-fill" width={30} color={'red'}></Icon>
          <Text> هناك خطأ الرجاء إعادة المحاولة</Text>
        </Message>
      ) : (
        <></>
      )}
      {message !== 'true' ? (
        <Button
          loading={loading}
          onClick={() => check()}
          color="darkRed"
          size="14rem"
        >
          <Text>التثبت من الإجابة</Text>
        </Button>
      ) : (
        <></>
      )}
    </StyledQuiz>
  )
}

export default Quiz
