import styled from 'styled-components'

export const Menu = styled.div`
  width: 100%;
  direction: rtl;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 0.5rem 1rem;
  gap: 0.5rem;
  box-sizing: border-box;
  border-top: 1px solid ${(props) => props.theme.colors.white};
`

export const Item = styled.div.attrs((props: { isActive: boolean }) => props)`
  width: 100%;
  padding: 0.75rem;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.white25 : null};
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
`
export const StyledA = styled.a`
  text-decoration: none;
`
