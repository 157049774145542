/* eslint-disable import/no-unused-modules */

import React, { ReactElement, useState, ChangeEvent } from 'react'
import { ResetPassword as StyledResetPassword } from './styled-components'
import Text from '../../text'
import Inputfield from '../../input/input-field'
import Button from '../../button'
import { resetPasswordRequest } from '../../../api/user'
import { Alert } from '../../../constants/sweet-alert'

interface ResetPasswordProps {
  onGoBackClick: () => void
}

function ResetPassword({ onGoBackClick }: ResetPasswordProps): ReactElement {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const verifyEmail = !email.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  )
  const onResetPasswordClick = () => {
    setLoading(true)

    if (!verifyEmail && !loading) {
      resetPasswordRequest({ email }).then((status) => {
        if (status) {
          Alert.fire({
            icon: 'success',
            title: 'تم إرسال رسالة إعادة تعيين كلمة المرور',
          })
          setLoading(false)
        } else {
          setLoading(false)

          Alert.fire({
            icon: 'error',
            title: 'لم يتم العثور على البريد إلكتروني',
          })
        }
      })
    } else {
      setLoading(false)
      Alert.fire({
        icon: 'warning',
        title: 'الرجاء التثبت من البريد إلكتروني ',
      })
    }
  }

  return (
    <StyledResetPassword>
      <Text center>لإسترجاع كلمتك السرية الرجاء إدخال بريدك الإلكتروني</Text>
      <Inputfield
        value={email}
        labelName="البريد اللإلكتروني"
        onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEmail(event.target.value)
        }
        onKeyPress={onResetPasswordClick}
      />
      <Button
        loading={loading}
        onClick={onResetPasswordClick}
        size="100%"
        color="darkRed"
      >
        <Text>إعادة تعيين كلمة السر</Text>
      </Button>
      <Button size="100%" onClick={onGoBackClick} color="white">
        <Text>الرجوع</Text>
      </Button>
    </StyledResetPassword>
  )
}

export default ResetPassword
