/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { DefaultTheme } from 'styled-components'
import Text from '../text'
import Icon from '../icon'
import { DownloadText } from './styled-components'

type NotificationMessageVariants = 'success' | 'pending' | 'error' | 'warning'
interface CertifDownloadStatusProps {
  message: string | ReactElement
  variant: NotificationMessageVariants
}

interface NotificationMessageVariantStyle {
  icon: string
  color: keyof DefaultTheme['colors']
}

type NotificationMessageVariantsType = {
  [key in NotificationMessageVariants]: NotificationMessageVariantStyle
}

const notificationMessageVariants: NotificationMessageVariantsType = {
  success: { color: 'green', icon: 'bi:check-circle-fill' },
  pending: { color: 'orange', icon: 'carbon:pending-filled' },
  error: { color: 'red', icon: 'akar-icons:circle-x-fill' },
  warning: { color: 'orange', icon: 'ic:round-warning' },
}

function NotificationMessage({
  message,
  variant,
}: CertifDownloadStatusProps): ReactElement {
  return (
    <DownloadText color={notificationMessageVariants[variant].color}>
      <Text size="small" weight="bold">
        {message}
      </Text>
      <Icon
        width={30}
        height={30}
        name={notificationMessageVariants[variant].icon}
      />
    </DownloadText>
  )
}
export default NotificationMessage
