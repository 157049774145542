/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { Part as PartType } from '../../../types/training'
import { StyledPart } from './styled-components'
import UnderlineText from '../../Underline-text'
import Number from '../../number'

export interface PartProps {
  part: PartType
  number: boolean
  onClick: (part: PartType) => void
}
function Part({ part, number, onClick }: PartProps): ReactElement {
  return (
    <StyledPart onClick={() => onClick(part)}>
      {number ? <Number number={part.placement} /> : null}
      {part.status === 'finished' ? (
        <UnderlineText color="green" value={part.title} />
      ) : null}
      {part.status === 'pending' ? (
        <UnderlineText color="orange" value={part.title} />
      ) : null}
      {part.status === null ? (
        <UnderlineText color="white" value={part.title} />
      ) : null}
    </StyledPart>
  )
}
export default Part
