const locations: { [key: string]: string } = {
  '0': 'عضو قيادة عامة',
  '1': 'باجة',
  '2': 'الكاف',
  '3': 'جندوبة',
  '4': 'بنزرت',
  '5': 'تونس',
  '6': 'بن عروس',
  '7': 'منوبة',
  '8': 'أريانة',
  '9': 'نابل',
  '10': 'زغوان',
  '11': 'سليانة',
  '12': 'القصرين',
  '13': 'سوسة',
  '14': 'المنستير',
  '15': 'المهدية',
  '16': 'القيروان',
  '17': 'سيدي بوزيد',
  '18': 'صفاقس',
  '19': 'قابس',
  '20': 'قفصة',
  '21': 'توزر',
  '22': 'قبلي',
  '23': 'تطاوين',
  '24': 'مدنين',
  '25': 'المهجر',
}
export default locations
