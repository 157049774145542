import styled from 'styled-components'

export const Number = styled.div`
  background-color: ${(props) => props.theme.colors.darkRed};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`
