import React, {
  ReactElement,
  useState,
  ChangeEvent,
  useEffect,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Profile as StyledProfile,
  Container,
  Inputs,
  Section,
  StyledImage,
  EditScreen,
  ImageButtonsGroup,
  InputsGroup,
  Div,
  StyledLink,
} from './styled-components'
import profileEdit from '../../assets/images/edit-profile.png'
import Inputfield from '../../components/input/input-field'
import { useConnectedUserContext } from '../../contexts/connected-user'
import Button from '../../components/button'
import Text from '../../components/text'
import { updateUser } from '../../api/user'
import { Alert } from '../../constants/sweet-alert'
import DashboardTemplate from '../../components/base-template/dashboard'
import locations from '../../constants/pdf-generator/locations'

const Profile = (): ReactElement => {
  const { connectedUser } = useConnectedUserContext()

  const navigate = useNavigate()

  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (connectedUser) {
      setPhone(connectedUser.phone.toString())
    }
  }, [connectedUser])

  const payload = useMemo(
    () => ({
      newPassword,
      password,
      phone,
    }),
    [password, phone, newPassword]
  )

  const verifyInputs = async () => {
    const errors = []
    const verifyNewPasswordLength = newPassword.length > 7 || newPassword === ''
    const verifyNewPasswordconfirm = newPassword === newPasswordConfirm
    const verifyPhone = phone.match(/^(\+|\d)[0-9]{7,16}$/)

    if (!verifyPhone) {
      errors.push('الهاتف')
    }
    if (!verifyNewPasswordLength) {
      errors.push('كلمة السر (8 أحرف على الأقل)')
    }
    if (!verifyNewPasswordconfirm) {
      errors.push('تطابق كلمة السر الجديدة')
    }
    if (!password) {
      errors.push('كلمة السر الحالية')
    }
    if (
      phone === connectedUser?.phone?.toString() &&
      (!newPassword || newPassword === password)
    ) {
      errors.push(' لم يتم إجراء أية تغييرات ')
    }

    return errors
  }

  const onUpdateUser = () => {
    verifyInputs().then((errors) => {
      if (errors.length === 0) {
        setLoading(true)
        updateUser(payload).then((status) => {
          if (status) {
            Alert.fire({
              icon: 'success',
              title: 'تم تحديث معطيات الحساب بنجاح',
            })
          } else {
            Alert.fire({
              icon: 'error',
              title: 'كلمة السر الحالية خاطئة',
            })
          }
          setLoading(false)
        })
      } else {
        const errorInputs = `<li>${errors.join('</li><li>')}</li>`
        Alert.fire({
          icon: 'warning',
          title: `الرجاء التثبت من المعطيات التالية <ul>${errorInputs}</ul>`,
        })
      }
    })
  }

  return (
    <DashboardTemplate title="تغيير معطيات الحساب">
      <EditScreen>
        <Section>
          <StyledProfile>
            <Container>
              <ImageButtonsGroup>
                <StyledImage src={profileEdit} />
                <Div>
                  <Button
                    loading={loading}
                    onClick={onUpdateUser}
                    color="darkRed"
                    size="100%"
                  >
                    <Text>تغيير معطيات الحساب</Text>
                  </Button>
                  <Button
                    size="100%"
                    color="white"
                    onClick={() => navigate('/dashboard')}
                  >
                    <Text>العودة الى الرئيسية</Text>
                  </Button>
                  <StyledLink to="/contact-us">
                    <Text center size="small">
                      إذا أردت تغيير معطيات المغلقة إتصل بنا
                    </Text>
                  </StyledLink>
                </Div>
              </ImageButtonsGroup>
              <InputsGroup>
                <Inputs>
                  <Inputfield
                    value={connectedUser?.firstName}
                    labelName="الإسم"
                    onValueChange={() => null}
                    disabled
                  />
                  <Inputfield
                    value={connectedUser?.lastName}
                    labelName="اللقب"
                    onValueChange={() => null}
                    disabled
                  />
                </Inputs>

                <Inputs>
                  <Inputfield
                    type="email"
                    value={connectedUser?.email}
                    labelName="البريد اللإلكتروني"
                    onValueChange={() => null}
                    disabled
                  />
                  <Inputfield
                    type="tel"
                    value={phone}
                    labelName="الهاتف"
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setPhone(event.target.value)
                    }
                  />
                </Inputs>
                <Inputfield
                  type="password"
                  value={password}
                  labelName="كلمة السر الحالية"
                  onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setPassword(event.target.value)
                  }
                />
                <Inputs>
                  <Inputfield
                    type="password"
                    value={newPassword}
                    labelName=" كلمة السر الجديدة"
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setNewPassword(event.target.value)
                    }
                  />
                  <Inputfield
                    type="password"
                    value={newPasswordConfirm}
                    labelName="تأكيد كلمة السر الجديدة"
                    onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setNewPasswordConfirm(event.target.value)
                    }
                  />
                </Inputs>
                <Inputs>
                  <Inputfield
                    value={connectedUser?.idKachefa || ' '}
                    labelName="المعرف الكشفي"
                    onValueChange={() => null}
                    disabled
                  />
                  <Inputfield
                    value={
                      connectedUser?.location
                        ? locations[connectedUser?.location]
                        : ' '
                    }
                    labelName="الجهة"
                    onValueChange={() => null}
                    disabled
                  />
                </Inputs>
              </InputsGroup>
            </Container>
          </StyledProfile>
        </Section>
      </EditScreen>
    </DashboardTemplate>
  )
}

export default Profile
