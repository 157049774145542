import React, { ChangeEventHandler, ReactElement } from 'react'
import { Inputbox, InputField } from './styled-components'
import Text from '../../text'

interface InputAreaProps {
  onValueChange: ChangeEventHandler<HTMLTextAreaElement>
  value: string | number | string[]
  labelName: string
}

function InputArea({
  onValueChange,
  value,
  labelName,
}: InputAreaProps): ReactElement {
  return (
    <InputField>
      <Text size="small">{labelName}</Text>
      <Inputbox
        placeholder={labelName}
        onChange={onValueChange}
        value={value}
      />
    </InputField>
  )
}

export default InputArea
