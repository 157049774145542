/* eslint-disable import/no-unused-modules */

import React, { ReactElement, useEffect, useState } from 'react'
import {
  TrainingCard as StyledTrainingCard,
  StyledText,
  Code,
  StyledLinkButton,
  Section,
} from './styled-components'
import { Training } from '../../../types/training'
import Text from '../../text'
import Icon from '../../icon'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Button from '../../button'

export interface TrainingCardProps {
  training: Training
  type: string
}

function TrainingCard({ training, type }: TrainingCardProps): ReactElement {
  const [responsiveMod, setResponsiveMod] = useState(false)
  const { width } = useWindowDimensions()
  const responsiveDimension = parseInt('768px'.replace('px', ''), 10)
  useEffect(() => {
    if (width && width < responsiveDimension) {
      setResponsiveMod(true)
    } else {
      setResponsiveMod(false)
    }
  }, [width, responsiveDimension])

  return (
    <StyledTrainingCard>
      <Code border={!!training.code}>
        <Text>{training.code}</Text>
      </Code>
      <Section>
        <StyledText>
          <Text>{training.title}</Text>
          {training.status === 'pending' ? (
            <Icon
              name="carbon:pending-filled"
              color="orange"
              height={40}
              width={40}
            />
          ) : null}
        </StyledText>

        <StyledLinkButton
          href={`/trainings?type=${type}&training_id=${training.id}`}
        >
          <Button size={responsiveMod ? '2.75rem' : '3.5rem'} color="darkRed">
            <Icon
              name="bi:arrow-left"
              color="white"
              width={responsiveMod ? 20 : 30}
              height={responsiveMod ? 20 : 30}
            />
          </Button>
        </StyledLinkButton>
      </Section>
    </StyledTrainingCard>
  )
}

export default TrainingCard
