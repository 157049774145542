/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { map } from 'lodash'
import { CategoryCardMatrix as StyledCategoryCardMatrix } from './styled-components'
import { Category } from '../../../types/training'
import CategoryCard from '../category-card'

export interface CategoryCardProps {
  categories: Category[] | undefined
  onClick: (category: Category) => void
}
function CategoryCardMatrix({
  categories,
  onClick,
}: CategoryCardProps): ReactElement {
  return (
    <StyledCategoryCardMatrix>
      {map(categories, (category: Category) => (
        <CategoryCard
          onClick={() => onClick(category)}
          key={category.id}
          category={category}
        />
      ))}
    </StyledCategoryCardMatrix>
  )
}

export default CategoryCardMatrix
