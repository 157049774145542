/* eslint-disable import/no-unused-modules */

import React, { ReactElement, ChangeEvent, useState, useMemo } from 'react'
import {
  Signin as StyledSignin,
  InputSection,
  IdKachefaSection,
  IdKachefaInput,
  SelectInput,
} from './styled-components'
import Text from '../../text'
import Inputfield from '../../input/input-field'
import Button from '../../button'
import InputSwitch from '../../input/input-switch'
import { signInUser } from '../../../api/user'
import { Alert } from '../../../constants/sweet-alert'
import { resendVerification } from '../../../api/email-verification'
import Select from '../../input/select'
import locationsOptions from '../../../constants/pdf-generator/locations-options'
// import Timer from './timer'

function Signin(): ReactElement {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [idKachefa, setIdKachefa] = useState('')
  const [location, setLocation] = useState<number>()
  const [showIdKachefa, setShowIdKachefa] = useState(false)

  // const [showTimer, setShowTimer] = useState(true)

  const [loading, setLoading] = useState(false)

  const payload = useMemo(
    () => ({
      firstName,
      lastName,
      password,
      email,
      phone,
      idKachefa,
      location,
    }),
    [firstName, lastName, password, email, phone, idKachefa, location]
  )

  const verifyIdKachefaInRange = (idK: string) => {
    const firstDigits = parseInt(idK?.slice(0, 2), 10)
    if (Number.isNaN(firstDigits)) return false
    if (!(firstDigits >= 0 && firstDigits < 25)) return false
    return true
  }

  const verifInputs = async () => {
    const errors = []
    const verifyEmail = !email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
    const verifyPassword = password.length < 7
    const verifyPasswordConfirm = password !== passwordConfirm
    const verifyFirstName = !firstName
    const verifyLastname = !lastName
    const verifyLocation = showIdKachefa && !location
    const verifyIdKachefa =
      showIdKachefa &&
      !(verifyIdKachefaInRange(idKachefa) && idKachefa?.length === 10)

    const verifyPhone = !phone.match(/^(\+|\d)[0-9]{7,16}$/)
    if (verifyFirstName) {
      errors.push('الإسم')
    }
    if (verifyLastname) {
      errors.push('اللقب')
    }

    if (verifyEmail) {
      errors.push('البريد اللإلكتروني')
    }
    if (verifyPhone) {
      errors.push('الهاتف')
    }
    if (verifyPassword) {
      errors.push('كلمة السر (8 أحرف على الأقل)')
    }
    if (verifyPasswordConfirm) {
      errors.push('تأكيد كلمة السر')
    }

    if (verifyIdKachefa) {
      errors.push('المعرف الكشفي')
    }

    if (verifyLocation) {
      errors.push('الجهة')
    }

    return errors
  }

  const onSigninClick = () => {
    setLoading(true)

    verifInputs().then((errors) => {
      if (errors.length === 0 && !loading) {
        signInUser(payload).then((user) => {
          if (typeof user !== 'number') {
            if (user.token) {
              resendVerification(user.email)
              localStorage.setItem('token', user.token)
              localStorage.setItem('isFirstLogin', 'true')
              window.location.replace('/dashboard')
              setLoading(false)
            }
          } else if (user === 403) {
            Alert.fire({
              icon: 'error',
              title: 'الحساب موجود، يمكنك إعادة تعيين كلمة المرور الخاصة',
            })
            setLoading(false)
          } else {
            Alert.fire({
              icon: 'error',
              title: 'خطأ في السيرفر، يرجى المحاولة مرة أخرى في وقت لاحق',
            })
            setLoading(false)
          }
        })
      } else {
        setLoading(false)
        const errorInputs = `<li>${errors.join('</li><li>')}</li>`
        Alert.fire({
          icon: 'warning',
          title: `الرجاء التثبت من المعطيات التالية <ul>${errorInputs}</ul>`,
        })
      }
    })
  }

  return (
    <>
      {/* {showTimer ? (
        <Timer onExpire={() => setShowTimer(false)} />
      ) : ( */}
      <StyledSignin>
        <InputSection>
          <Inputfield
            value={lastName}
            labelName="اللقب"
            onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              setLastName(event.target.value)
            }
            onKeyPress={onSigninClick}
          />
          <Inputfield
            value={firstName}
            labelName="الإسم"
            onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFirstName(event.target.value)
            }
            onKeyPress={onSigninClick}
          />
        </InputSection>
        <InputSection>
          <Inputfield
            typeFocus="tel"
            value={phone}
            labelName="الهاتف"
            onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPhone(event.target.value)
            }
            onKeyPress={onSigninClick}
          />
          <Inputfield
            typeFocus="email"
            value={email}
            labelName="البريد اللإلكتروني"
            onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEmail(event.target.value.toLowerCase())
            }
            onKeyPress={onSigninClick}
          />
        </InputSection>

        <InputSection>
          <Inputfield
            typeFocus="password"
            value={passwordConfirm}
            labelName="تأكيد كلمة السر"
            onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPasswordConfirm(event.target.value)
            }
            onKeyPress={onSigninClick}
          />
          <Inputfield
            typeFocus="password"
            value={password}
            labelName="كلمة السر"
            onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
              setPassword(event.target.value)
            }
            onKeyPress={onSigninClick}
          />
        </InputSection>

        <IdKachefaSection>
          <Text lineHeight={32} size="small">
            هل أنت من المنخريطين في الكشافة التونسية؟
          </Text>
          <IdKachefaInput>
            <InputSwitch
              onChange={() => {
                setShowIdKachefa(!showIdKachefa)
                setIdKachefa('')
              }}
              checked={showIdKachefa}
            />
          </IdKachefaInput>
        </IdKachefaSection>
        {showIdKachefa ? (
          <InputSection>
            <SelectInput>
              <Text size="small">الجهة</Text>
              <Select
                options={locationsOptions}
                onChange={(item) => setLocation(item.value as number)}
              />
            </SelectInput>
            <Inputfield
              value={idKachefa}
              labelName="المعرف الكشفي"
              onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
                setIdKachefa(event.target.value)
              }
            />
          </InputSection>
        ) : null}

        <Button
          size="100%"
          onClick={() => {
            onSigninClick()
          }}
          color="darkRed"
          loading={loading}
        >
          <Text weight="bold">إنشاء حساب</Text>
        </Button>
      </StyledSignin>
      {/* )} */}
    </>
  )
}
export default Signin
