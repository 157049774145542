/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import Switch from 'react-switch'
import theme from '../../../theme'

interface InputSwitchProps {
  onChange: () => void
  checked: boolean
}

function InputSwitch({ onChange, checked }: InputSwitchProps): ReactElement {
  return (
    <Switch
      uncheckedIcon={false}
      onChange={onChange}
      checked={checked}
      onColor={theme.colors.darkRed}
      offColor={theme.colors.grey}
      handleDiameter={20}
    />
  )
}

export default InputSwitch
