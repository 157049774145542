import styled from 'styled-components'

export const DashboardTemplate = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 20rem);
  box-sizing: border-box;
  padding: 0 6rem 1.5rem 6rem;
  position: ${(props) => (props.responsiveMod ? 'fixed' : '')};
  position: ${(props) => (props.responsiveMod ? 'fixed' : '')};
  @media (min-width: ${(props) => props.theme.responsive.wideScreen}) {
    padding: 1.5rem 18rem;
  }

  @media (max-width: ${(props) => props.theme.responsive.laptopL}) {
    padding: 0 5rem 1.25rem;
  }

  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 100%;
    padding: 0 3rem 1.25rem;
  }

  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    width: 100%;
    padding: 0 2rem 1.25rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    width: 100%;
    padding: 0 1.25rem 1.25rem;
  }
`
export const StyledChildren = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  gap: 2rem;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    filter: ${(props) => (props.responsiveMod ? 'blur(3px)' : '')};
  }
`
export const StyledIcon = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  display: none;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.colors.red};
    cursor: pointer;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    border-radius: 20px;
  }
`
export const Bar = styled.div.attrs(
  (props: { responsiveMod: boolean }) => props
)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    justify-content: space-between;
  }
`

export const StyledImg = styled.img`
  width: 9rem;
  height: 9rem;
  object-fit: contain;
`

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    gap: 0.5rem;
  }
`
export const ReturnButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.red};
  cursor: pointer;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  border-radius: 20px;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    padding: 0.25rem 0.5rem;
  }
`
export const StyledText = styled.div.attrs(
  (props: { returnUrl: string }) => props
)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${(props) => (props.returnUrl ? '54px' : '0')};
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    padding: 0;
  }
`
