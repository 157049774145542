import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledImg = styled.img`
  margin-top: -1rem;
  width: 9rem;
`

export const EditScreen = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    gap: 1rem;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    gap: 1rem;
  }
`

export const StyledImage = styled.img`
  width: 19rem;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 22rem;
    display: none;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 12rem;
    display: none;
  }
`

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 90%;
    padding: 0rem;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: row;
  gap: 2rem;
  padding: 3rem 5rem;
  box-shadow: ${(props) => props.theme.boxShadow.shadow1};
  border-radius: 20px;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 90%;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    flex-direction: column-reverse;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    flex-direction: column-reverse;
    width: 90%;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
`

export const InputsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 2rem;
`
export const ImageButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
`

export const Inputs = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    flex-wrap: wrap;
  }
`
export const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  align-items: center;
`
export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.black};
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  direction: rtl;
  &:hover::after {
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0.125rem;
    background: ${(props) => props.theme.colors.darkRed};
    transition: width 0.4s;
  }
`
