/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { Number as StyledNumber } from './styled-components'
import Text from '../text'

export interface NumberProps {
  number: number
}
function Number({ number }: NumberProps): ReactElement {
  return (
    <StyledNumber>
      <Text size="small" color="white">
        {number}
      </Text>
    </StyledNumber>
  )
}

export default Number
