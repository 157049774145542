import React, { ReactElement, useEffect, useState } from 'react'
import { map } from 'lodash'
import { SwiperSlide, Swiper } from 'swiper/react/swiper-react'
import { Navigation, Pagination } from 'swiper'
import Part from '../part'
import { Part as PartType } from '../../../types/training'
import { PartMatrix as StyledPartMatrix } from './styled-components'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { SwiperRefType } from '../../../types/swiper-js'

interface PartMatrixProps {
  parts: PartType[]
  onClick: (part: PartType) => void
  setSwiperRef: (swiper: SwiperRefType) => void
}
function PartMatrix({
  parts,
  onClick,
  setSwiperRef,
}: PartMatrixProps): ReactElement {
  const [responsiveMod, setResponsiveMod] = useState(false)
  const { width } = useWindowDimensions()

  const responsiveDimension = parseInt('425px'.replace('px', ''), 10)

  useEffect(() => {
    if (width && width < responsiveDimension) {
      setResponsiveMod(true)
    } else {
      setResponsiveMod(false)
    }
  }, [width, responsiveDimension])
  const pagination = {
    clickable: true,
    renderBullet(index: number, className: string) {
      return `<span class="${className}">${index + 1}</span>`
    },
  }

  return (
    <StyledPartMatrix responsiveMod={responsiveMod}>
      <Swiper
        pagination={responsiveMod ? pagination : false}
        navigation={true}
        slidesPerView={responsiveMod ? 1 : 'auto'}
        className="mySwiper"
        observer={true}
        observeParents={true}
        parallax={true}
        dir="rtl"
        spaceBetween={10}
        modules={[Navigation, Pagination]}
        onSlideChange={(swiper) => onClick(parts[swiper.activeIndex])}
        onSwiper={setSwiperRef}
      >
        {map(parts, (part: PartType) => (
          <SwiperSlide key={part.id}>
            <Part
              onClick={() => {
                onClick(part)
              }}
              number={!responsiveMod}
              key={part.id}
              part={part}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledPartMatrix>
  )
}

export default PartMatrix
