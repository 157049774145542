/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-unused-modules */
import React, { ReactElement, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  DashboardTemplate as StyledDashboardTemplate,
  StyledIcon,
  Bar,
  StyledImg,
  StyledChildren,
  Title,
  ReturnButton,
  StyledText,
} from './styled-components'
import RightSidePage from './right-side-page'
import { useConnectedUserContext } from '../../../contexts/connected-user'
import Icon from '../../icon'
import logo from '../../../assets/images/logo.png'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import Text from '../../text'

export interface DashboardTemplateProps {
  active?: string
  children: ReactElement[] | ReactElement
  returnUrl?: string
  title?: string
}
function DashboardTemplate({
  children,
  active,
  returnUrl,
  title,
}: DashboardTemplateProps): ReactElement {
  const { connectedUser } = useConnectedUserContext()
  const [responsiveMod, setResponsiveMod] = useState(false)
  const { width } = useWindowDimensions()
  const navigate = useNavigate()

  useEffect(() => {
    if (width && width > 768) {
      setResponsiveMod(false)
    }
  }, [width])

  useEffect(() => {
    if (responsiveMod) {
      history.pushState(null, '', location.href)
      window.onpopstate = () => {
        setResponsiveMod(false)
      }
    } else {
      window.onpopstate = () => {
        navigate(-1)
      }
    }
  })

  return (
    <StyledDashboardTemplate responsiveMod={responsiveMod}>
      <Bar responsiveMod={responsiveMod}>
        <Link to="/dashboard">
          <StyledImg src={logo} alt="logo" />
        </Link>
        <StyledIcon
          responsiveMod={responsiveMod}
          onClick={() => setResponsiveMod(!responsiveMod)}
        >
          <Icon
            name={responsiveMod ? 'clarity:home-line' : 'eva:bar-chart-fill'}
            rotate={responsiveMod ? '' : '270deg'}
            color="darkRed"
            width={20}
            height={20}
          />
        </StyledIcon>
      </Bar>
      <Title>
        <StyledText returnUrl={returnUrl}>
          <Text center weight="bold" size="big">
            {title}
          </Text>
        </StyledText>
        {returnUrl ? (
          <ReturnButton onClick={() => navigate(returnUrl)}>
            <Icon
              name="eva:arrow-back-outline"
              rotate="180deg"
              color="darkRed"
              width={20}
              height={20}
            />
          </ReturnButton>
        ) : null}
      </Title>
      <StyledChildren responsiveMod={responsiveMod}>{children}</StyledChildren>
      <RightSidePage
        onClickHide={() => setResponsiveMod(false)}
        responsiveMod={responsiveMod}
        active={active}
        user={connectedUser}
      />
    </StyledDashboardTemplate>
  )
}

export default DashboardTemplate
