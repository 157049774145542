import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ComingSoonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2rem;
  overflow: hidden;
  width: 100%;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
  }
`
export const StyledImg = styled.img`
  width: 22rem;
  object-fit: contain;
  box-sizing: border-box;
  gap: 0.5rem;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 80%;
  }

  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    width: 100%;
  }
`
export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 40%;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 60%;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    width: 100%;
  }
`
