import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'
import { find, findIndex, last, map, orderBy } from 'lodash'
import { claimCertificate } from '../../api/certificate'
import { sendProgress } from '../../api/progress'
import {
  Category as CategoryType,
  Part,
  Training as TrainingType,
} from '../../types/training'
import { useConnectedUserContext } from '../../contexts/connected-user'
import { Alert } from '../../constants/sweet-alert'
import {
  PartContent,
  TextSection,
  StyledImg,
  UnfinishedPart,
  PartScroll,
} from './styled-components'
import DashboardTemplate from '../../components/base-template/dashboard'
import PartMatrix from '../../components/training/parts-matrix'
import Button from '../../components/button'
import Text from '../../components/text'
import Quiz from '../../components/quiz'
import { SwiperRefType } from '../../types/swiper-js'
import unfinishedPart from '../../assets/images/unfinished-part.png'
import { SelectItems } from '../../types/select'

interface CategoryScreenProps {
  training: TrainingType
  category: CategoryType
  type: string
}

const Category = ({
  training,
  category,
  type,
}: CategoryScreenProps): ReactElement => {
  const { connectedUser } = useConnectedUserContext()
  const [disabledButton, setDisabledButton] = useState(false)
  const [formattedCategory, setFormattedCategory] = useState<CategoryType>()
  const [loading, setLoading] = useState(false)
  const scrollTopRef = useRef<null | HTMLDivElement>(null)
  const navigate = useNavigate() // used to redirect
  const [nextBtn, setNextBtn] = useState('التالي')

  const currentProgress = find(connectedUser?.progress, {
    training: training.id,
  })
  // get category using api request
  useEffect(() => {
    const verifyCategory =
      training?.categories.findIndex((e) => e.id === category.id) ===
      training?.categories.findIndex(
        (e) => e.id === currentProgress?.category.id
      )
    let formattedData: Part[] = []
    if (verifyCategory) {
      formattedData = map(
        orderBy(category?.parts, ['placement']),
        (part: Part) =>
          currentProgress && part.placement < currentProgress?.partPlacement
            ? {
                ...part,
                status: 'finished',
              }
            : part.placement === currentProgress?.partPlacement
            ? {
                ...part,
                status: 'pending',
              }
            : {
                ...part,
                status: null,
              }
      )
    } else {
      formattedData = map(
        orderBy(category?.parts, ['placement']),
        (part: Part) => ({
          ...part,
          status: 'finished',
        })
      )
    }

    if (category) {
      setFormattedCategory({ ...category, parts: formattedData })
    }
  }, [category, currentProgress, training])

  // Verify category status is equal to null ? ==> can't acces
  if (!currentProgress && formattedCategory?.placement !== 1) {
    navigate(`/trainings?type=${type}&training_id=${training.id}`)
  } else if (
    formattedCategory &&
    currentProgress &&
    formattedCategory?.placement > currentProgress.category.placement + 1
  ) {
    Alert.fire({
      icon: 'warning',
      title: `عليك إنهاء الأجزاء السابقة`,
    })
    navigate(`/trainings?type=${type}&training_id=${training.id}`)
  } else if (
    formattedCategory &&
    currentProgress &&
    formattedCategory?.placement > currentProgress?.category.placement
  ) {
    Alert.fire({
      icon: 'warning',
      title: `عليك إنهاء الأجزاء السابقة`,
    })
    navigate(`/trainings?type=${type}&training_id=${training.id}`)
  }

  const [selectedPart, setSelectedPart] = useState<Part | undefined>()
  const [currentPart, setCurrentPart] = useState<Part | undefined>()

  // setting current part

  useEffect(() => {
    if (formattedCategory) {
      setCurrentPart(
        find(formattedCategory?.parts, {
          placement: currentProgress?.partPlacement,
        })
      )
    }
  }, [formattedCategory, currentProgress?.partPlacement])
  // setting selected part

  useEffect(() => {
    if (currentPart) {
      setSelectedPart(currentPart)
    }
  }, [currentPart])

  useEffect(() => {
    if (selectedPart) {
      if (
        selectedPart.placement === last(formattedCategory?.parts)?.placement
      ) {
        setNextBtn('القسم التالي')
      } else if (
        selectedPart.isWhat !== 'quiz' ||
        selectedPart.status === 'finished'
      ) {
        setDisabledButton(false)
      }
    }
  }, [selectedPart, formattedCategory])

  const scrollToTop = () => {
    scrollTopRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const onPartClick = (part: Part) => {
    scrollToTop()
    const verifyStatus = find(formattedCategory?.parts, part)?.status === null
    if (!verifyStatus) {
      setSelectedPart(part)
    } else {
      setSelectedPart(undefined)
      setDisabledButton(true)
    }
  }
  const buttonSwitch = (status: boolean) => {
    setDisabledButton(status)
  }

  const [swiperRef, setSwiperRef] = useState<SwiperRefType>()

  const slideTo = (index: number) => {
    if (swiperRef) {
      scrollToTop()
      swiperRef.slideTo(index)
    }
  }

  const onPartChange = () => {
    setLoading(true)
    if (selectedPart && currentPart && formattedCategory) {
      scrollToTop()
      if (
        selectedPart.placement !== last(formattedCategory?.parts)?.placement
      ) {
        const categorycopy = formattedCategory
        const selectedPartIndex = findIndex(formattedCategory?.parts, {
          placement: selectedPart?.placement,
        })

        const nextPartIndex = selectedPartIndex + 1

        categorycopy.parts[selectedPartIndex].status = 'finished'
        if (categorycopy.parts[nextPartIndex].status !== 'finished') {
          categorycopy.parts[nextPartIndex].status = 'pending'
        }
        setFormattedCategory(categorycopy)
        setSelectedPart(formattedCategory.parts[nextPartIndex])
        setCurrentPart(formattedCategory.parts[nextPartIndex])
        slideTo(nextPartIndex)
        setLoading(false)
      } else if (training && currentProgress) {
        const nextCateg = find(training.categories, {
          placement: formattedCategory.placement + 1,
        })
        if (
          nextCateg &&
          formattedCategory.placement !== last(training.categories)?.placement
        ) {
          const progressCopy = {
            isFinished: 'false',
            training: currentProgress.training,
            category: nextCateg.id,
            partPlacement: 1,
          }
          const progCateg = find(training.categories, {
            id: currentProgress?.category.id,
          })
          if (progCateg && nextCateg.placement >= progCateg.placement) {
            sendProgress(progressCopy).then((res) => {
              if (res) {
                window.location.replace(
                  `/trainings?type=${type}&training_id=${training.id}&category_id=${nextCateg.id}`
                )
              }
            })
          } else {
            window.location.replace(
              `/trainings?type=${type}&training_id=${training.id}&category_id=${nextCateg.id}`
            )
          }
        } else {
          // Claim certificate with current progress (progress is deleted)
          claimCertificate(currentProgress?.id).then((response) => {
            if (response === 201)
              return window.location.replace(`/getcertificate/${training.id}`)
            Alert.fire({
              icon: 'error',
              title: `حدث خطأ`,
            })
            setLoading(false)
            return ''
          })
        }
      }
    }
  }
  if (formattedCategory) {
    return (
      <DashboardTemplate title={category.title}>
        <PartContent>
          {selectedPart?.isWhat === 'info' ? (
            <PartScroll ref={scrollTopRef}>
              <Text weight="bold" size="big">
                {selectedPart?.title}
              </Text>
              <TextSection>
                {selectedPart?.text
                  ? JSON.parse(selectedPart?.text).map(
                      (section: SelectItems) => (
                        <div key={section.id}>
                          {parse(
                            typeof section.value === 'string'
                              ? section.value
                              : ''
                          )}
                        </div>
                      )
                    )
                  : null}
              </TextSection>
            </PartScroll>
          ) : selectedPart?.isWhat === 'quiz' ? (
            <PartScroll ref={scrollTopRef}>
              <Quiz
                part={selectedPart}
                buttonSwitch={buttonSwitch}
                progStatus={selectedPart?.status}
              />
            </PartScroll>
          ) : (
            <UnfinishedPart>
              <StyledImg src={unfinishedPart} />
              <Text center size="big">
                عليك إنهاء الأجزاء السابقة
              </Text>
            </UnfinishedPart>
          )}
        </PartContent>
        <PartMatrix
          setSwiperRef={setSwiperRef}
          onClick={(part) => onPartClick(part)}
          parts={formattedCategory.parts}
        />
        <Button
          onClick={disabledButton ? () => null : onPartChange}
          color={disabledButton ? 'grey' : 'darkRed'}
          size="250px"
          loading={loading}
        >
          <Text>{nextBtn}</Text>
        </Button>
      </DashboardTemplate>
    )
  }
  return <></>
}

export default Category
