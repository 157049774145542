/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { DefaultTheme } from 'styled-components'
import ReactLoading from 'react-loading'
import { Button as StyledButton } from './styled-components'

interface ButtonProps {
  children: ReactElement | string
  size?: string
  onClick?: () => void
  color: keyof DefaultTheme['colors']
  loading?: boolean
}

function Button({
  children,
  onClick,
  size,
  color,
  loading,
}: ButtonProps): ReactElement {
  return (
    <StyledButton
      size={size}
      onClick={loading ? () => null : onClick}
      color={color}
    >
      {loading ? (
        <ReactLoading
          type="spin"
          color="white"
          height={'1.5rem'}
          width={'1.5rem'}
        />
      ) : (
        children
      )}
    </StyledButton>
  )
}

export default Button
