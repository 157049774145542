import styled from 'styled-components'
import Text from '../../text'

export const Login = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2.5rem;
`

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
export const StyledTex = styled(Text)`
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  &:hover::after {
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0.125rem;
    background: ${(props) => props.theme.colors.darkRed};
    transition: width 0.4s;
  }
`
