import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const NotFoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  gap: 2rem;
  overflow: hidden;
  width: 100%;
`
export const StyledImg = styled.img`
  width: 60%;
  padding: 0 2rem;
  object-fit: contain;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 100%;
  }
`
export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  width: 60%;
  padding: 0 2rem;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 100%;
  }
`
