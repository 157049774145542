import axiosRequest from '../constants/axios-request'
import { ProgressParams, Progress } from '../types/progress'

export const sendProgress = (progress: ProgressParams): Promise<number> =>
  axiosRequest
    .post('/progress', progress)
    .then((result) => result.status)
    .catch((error) => error.response.status)

export const getProgress = (id: string | undefined): Promise<Progress> =>
  axiosRequest
    .get(`/progress/${id}`)
    .then((result) => result.data)
    .catch((error) => error.response.status)
