/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import ReactLoading from 'react-loading'
import { Loading as StyledLoading } from './styled-components'

function Loading(): ReactElement {
  return (
    <StyledLoading>
      <ReactLoading type="spin" color="red" height={'6rem'} width={'6rem'} />
    </StyledLoading>
  )
}

export default Loading
