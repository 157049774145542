/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { DefaultTheme } from 'styled-components'
import { UnderlineText as Underline } from './styled-components'
import Text from '../text'

interface UnderlineTextProps {
  onClick?: () => void
  color: keyof DefaultTheme['colors']
  value: string
}
function UnderlineText({
  onClick,
  color,
  value,
}: UnderlineTextProps): ReactElement {
  return (
    <Underline onClick={onClick} color={color}>
      <Text size="small">{value}</Text>
    </Underline>
  )
}

export default UnderlineText
