/* eslint-disable import/no-unused-modules */

import React, { ReactElement, useEffect, useState } from 'react'

import CertificationCard from '../certification-card'
import { useConnectedUserContext } from '../../contexts/connected-user'
import NotificationMessage from '../certifDownloadStatus'
import { getTrainingGroups } from '../../api/training-group'
import { TrainingGroup } from '../../types/training'

function CertificationCardMatrix(): ReactElement {
  const [trainingsGroups, setTrainingsGroups] = useState<TrainingGroup[]>()
  useEffect(() => {
    getTrainingGroups().then((data) => {
      setTrainingsGroups(data)
    })
  }, [])

  const { connectedUser } = useConnectedUserContext()
  const [downloadText, setDownloadText] = useState('')
  const [downloadStatus, setDownloadStatus] = useState<
    'success' | 'pending' | 'error'
  >()

  const DownloadStatus = (
    text: string,
    status: 'success' | 'pending' | 'error'
  ) => {
    setDownloadText(text)
    setDownloadStatus(status)
    window.scrollTo(0, 0)
  }
  return (
    <>
      {downloadText && downloadStatus ? (
        <NotificationMessage variant={downloadStatus} message={downloadText} />
      ) : null}

      {trainingsGroups?.map((trainingsGroup) =>
        connectedUser?.idKachefa || trainingsGroup.isPublic ? (
          <CertificationCard
            key={trainingsGroup.id}
            DownloadStatus={DownloadStatus}
            trainingsGroup={trainingsGroup}
          />
        ) : null
      )}
    </>
  )
}

export default CertificationCardMatrix
