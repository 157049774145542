import axiosRequest from '../constants/axios-request'
import { TrainingGroup } from '../types/training'

export const getTrainingGroups = (): Promise<TrainingGroup[]> =>
  axiosRequest
    .get('/training-group')
    .then((result) => result.data)
    .catch((error) => error.response.status)

export const getTrainingGroup = (
  type: string | null,
  training_id: string | null,
  category_id: string | null
): Promise<TrainingGroup> =>
  axiosRequest
    .get(
      `/training-group?type=${type}&training_id=${training_id}&category_id=${category_id}`
    )
    .then((result) => result.data)
    .catch((error) => error.response.status)
