import React, { ReactElement } from 'react'
import { Part, Question } from '../../../types/training'
import { Questions as StyledQuestions } from '../styled-components'

import InputRadio from '../../input/radio'
import { SelectItems } from '../../../types/select'

interface QuizTickProps {
  part: Part | undefined
  onValueChange: (selectedItem: SelectItems, question: Question) => void
}

function QuizTrueFalse({ part, onValueChange }: QuizTickProps): ReactElement {
  return (
    <StyledQuestions>
      {part?.questions.map((question, index) => (
        <InputRadio
          key={question.id}
          question={question}
          onValueChange={(e) => {
            const selectedItem = {
              id: index,
              value: e.target.value,
              label: e.target.value,
            }
            onValueChange(selectedItem, question)
          }}
        ></InputRadio>
      ))}
    </StyledQuestions>
  )
}

export default QuizTrueFalse
