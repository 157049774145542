/* eslint-disable import/no-unused-modules */

import React, { ReactElement, ChangeEventHandler } from 'react'
import { Question } from '../../../types/training'
import {
  Radio,
  StyledText,
  Inputs,
  StyledInput,
  StyledTitle,
  Element,
} from './styled-components'

interface RadioInputProps {
  question: Question
  onValueChange?: ChangeEventHandler<HTMLInputElement>
}

function RadioInput({
  question,
  onValueChange,
}: RadioInputProps): ReactElement {
  return (
    <Radio>
      <StyledTitle>{question.text}</StyledTitle>
      <Inputs>
        <Element>
          <StyledInput
            type="radio"
            name={String(question.id)}
            value={question.answers[0]}
            id={String(question.id)}
            onChange={onValueChange}
          />
          <StyledText>{question.answers[0]}</StyledText>
        </Element>
        <Element>
          <StyledInput
            type="radio"
            name={String(question.id)}
            value={question.answers[1]}
            id={String(question.id)}
            onChange={onValueChange}
          />
          <StyledText>{question.answers[1]}</StyledText>
        </Element>
      </Inputs>
    </Radio>
  )
}

export default RadioInput
