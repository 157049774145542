/* eslint-disable react-hooks/exhaustive-deps */

import axios from 'axios'
import React, { ReactElement, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import humps from 'humps'
import { useConnectedUserContext } from '../contexts/connected-user'
import Loading from '../components/loading'
/* import NeedVerification from '../components/connexion/email-verification/need-verification' */
import { User } from '../types/user'
import { Alert } from '../constants/sweet-alert'
import NeedVerification from '../components/connexion/email-verification/need-verification'

const ProtectedRoutes = (): ReactElement => {
  const location = useLocation()
  const { connectedUser, setConnectedUser } = useConnectedUserContext()
  const [loading, setLoading] = useState(true)

  localStorage.setItem('redirectUrl', location.pathname)

  const connectedUserToken = localStorage.token || undefined
  const { isFirstLogin } = localStorage

  useEffect(() => {
    if (connectedUserToken) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user`, {
          headers: { 'x-access-token': `${connectedUserToken}` },
        })
        .then((result) => {
          const user = humps.camelizeKeys(result.data) as unknown as User
          setConnectedUser(user)
          setLoading(false)
          if (isFirstLogin === 'true') {
            Alert.fire({
              icon: 'success',
              title: `مرحبا ${user.firstName} ${user.lastName}`,
            })
            localStorage.setItem('isFirstLogin', 'false')
          }
        })
        .catch(() => {
          setLoading(false)
          localStorage.removeItem('token')
        })
    } else {
      localStorage.removeItem('token')
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <Loading />
  }

  if (connectedUser) {
    return connectedUser.emailVerified ? (
      <Outlet />
    ) : (
      <NeedVerification color="orange" />
    )
  }

  return <Navigate to="/" />
}

export default ProtectedRoutes
