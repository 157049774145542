import styled from 'styled-components'

export const Matrix = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 100%;
  }
`

export const TextZone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem 0;
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 100%;
    padding: 0;
  }
`
