import styled from 'styled-components'

export const StyledImage = styled.img`
  width: 40%;
  height: 40%;
  object-fit: contain;

  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    width: 80%;
  }

  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    width: 70%;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileS}) {
    width: 100%;
  }
`
