import styled from 'styled-components'
import Text from '../text'

export const Quiz = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-items: center;
  gap: 1.5rem;
`
export const Message = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`
export const Response = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 70%;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.responsive.tablet}) {
    display: flex;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    display: none;
  }
`
export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
  gap: 1.5rem;
`
export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
`

export const QuestionTick = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
export const QuestionSelect = styled.div`
  gap: 1rem;
  width: 20%;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 12rem;
  }
`

export const QuestionText = styled(Text)`
  width: 70%;
  word-wrap: break-word;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 100%;
  }
`
export const QuestionTextTickBox = styled(Text)`
  width: 100%;
  word-wrap: break-word;
  @media (max-width: ${(props) => props.theme.responsive.laptopM}) {
    width: 100%;
  }
`
