import React, { ReactElement } from 'react'
import { StyledImg, NotFoundContainer, StyledLink } from './styled-components'
import Text from '../../components/text'
import Button from '../../components/button'
import NotFoundImg from '../../assets/images/404.png'

const NotFound = (): ReactElement => (
  <>
    <NotFoundContainer>
      <StyledImg src={NotFoundImg} />

      <StyledLink to="/dashboard">
        <Button size="40rem" color="darkRed">
          <Text>العودة إلى الرئيسية</Text>
        </Button>
      </StyledLink>
    </NotFoundContainer>
  </>
)
export default NotFound
