import React, { ReactElement, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Confetti from 'react-confetti'
import DashboardTemplate from '../../components/base-template/dashboard'
import congratulationImage from '../../assets/images/congratulation.png'
import { StyledImg, GetCertificateContainer } from './styled-components'
import Text from '../../components/text'
import Button from '../../components/button'
import { useConnectedUserContext } from '../../contexts/connected-user'
import NotificationMessage from '../../components/certifDownloadStatus'
import DownloadPdf from '../../constants/pdf-generator/download-pdf'

const GetCertificateScreen = (): ReactElement => {
  const { trainingId } = useParams<string>()
  const { connectedUser } = useConnectedUserContext()
  const navigate = useNavigate()

  const certificationVerify = connectedUser?.certifications.find(
    (element) => element.training.id === trainingId
  )
  const [downloadStatus, setDownloadStatus] = useState<
    'success' | 'pending' | 'error' | undefined
  >()

  const [downloadText, setDownloadText] = useState<string>('')

  const DownloadStatus = (
    text: string,
    status: 'success' | 'pending' | 'error'
  ) => {
    setDownloadText(text)
    setDownloadStatus(status)
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Confetti tweenDuration={50000} recycle={false} numberOfPieces={3000} />
      <DashboardTemplate active="primary-training">
        <>
          {downloadText && downloadStatus ? (
            <NotificationMessage
              variant={downloadStatus}
              message={downloadText}
            />
          ) : null}
        </>
        <GetCertificateContainer>
          <StyledImg src={congratulationImage} />
          <Text size="big">لقد اتممت الدورة بنجاح</Text>
          <Button
            size="16rem"
            color="darkRed"
            onClick={() => {
              DownloadPdf(
                DownloadStatus,
                certificationVerify?.id,
                connectedUser
              )
            }}
          >
            <Text>تحميل الشهادة</Text>
          </Button>
          <Button
            size="16rem"
            color="white"
            onClick={() => navigate('/dashboard')}
          >
            <Text>العودة الى الرئيسية</Text>
          </Button>
        </GetCertificateContainer>
      </DashboardTemplate>
    </>
  )
}
export default GetCertificateScreen
