import Swal from 'sweetalert2'

export const Alert = Swal.mixin({
  toast: true,
  position: 'top',
  customClass: { container: 'swal-direction' },
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})
