import axiosRequest from '../constants/axios-request'

interface ResponseType {
  id: string
  answer: string[]
}
interface VerifyQuestionResponse {
  result: boolean
}

export const checkAnswers = (
  answer: ResponseType[]
): Promise<VerifyQuestionResponse> =>
  axiosRequest
    .post(`/question/verify`, { answer })
    .then((result) => result.data)
