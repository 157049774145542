import * as z from 'zod'

export const schema = z.object({
  email: z.string().min(1).email(),
  subject: z.string().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  text: z.string().min(1),
})
export type DataTypeSchema = z.infer<typeof schema>
