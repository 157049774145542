/* eslint-disable import/no-unused-modules */

import React, { ReactElement, useState, ChangeEvent } from 'react'
import {
  Login as StyledLogin,
  ForgotPassword,
  StyledTex,
} from './styled-components'
import Text from '../../text'
import Inputfield from '../../input/input-field'
import Button from '../../button'
import { loginUser } from '../../../api/user'
import { Alert } from '../../../constants/sweet-alert'

interface LoginProps {
  onForgotPasswordClick: () => void
}

function Login({ onForgotPasswordClick }: LoginProps): ReactElement {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const onLoginClick = () => {
    const payload = { email, password }
    if (email && password) {
      setLoading(true)
      loginUser(payload).then((user) => {
        if (typeof user !== 'number') {
          localStorage.setItem('token', user.token)
          const redirectUrl = localStorage.getItem('redirectUrl')
          localStorage.setItem('isFirstLogin', 'true')
          if (redirectUrl) {
            window.location.replace(redirectUrl)
          } else {
            window.location.replace('/dashboard')
          }
        } else if (user === 404) {
          setLoading(false)
          Alert.fire({ icon: 'warning', title: 'الرجاء التثبت من المعطيات' })
        } else {
          Alert.fire({
            icon: 'error',
            title: 'خطأ في السيرفر، يرجى المحاولة مرة أخرى في وقت لاحق',
          })
          setLoading(false)
        }
      })
    } else {
      Alert.fire({ icon: 'warning', title: 'الرجاء التثبت من المعطيات' })
    }
  }

  return (
    <StyledLogin>
      <Inputfield
        value={email}
        labelName="البريد اللإلكتروني أو المعرف الكشفي"
        onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
          setEmail(event.target.value)
        }
        type="email"
        onKeyPress={onLoginClick}
      />
      <Inputfield
        value={password}
        labelName="كلمة السر"
        onValueChange={(event: ChangeEvent<HTMLInputElement>) =>
          setPassword(event.target.value)
        }
        type="password"
        onKeyPress={onLoginClick}
      />
      <ForgotPassword>
        <StyledTex size="small" onClick={onForgotPasswordClick}>
          نسيت كلمة السر؟
        </StyledTex>
      </ForgotPassword>
      <Button
        loading={loading}
        size="100%"
        onClick={onLoginClick}
        color="darkRed"
      >
        <Text weight="bold">تسجيل الدخول</Text>
      </Button>
    </StyledLogin>
  )
}

export default Login
