/* eslint-disable react-hooks/exhaustive-deps */

import React, { ReactElement, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getTrainingGroup } from '../api/training-group'
import { useConnectedUserContext } from '../contexts/connected-user'
import { TrainingGroup as TrainingGroupType } from '../types/training'
import Category from './category'
import NotFound from './not-found'
import TrainingGroupScreen from './training-group'
import Training from './training'

const ProtectedRoutesTraining = (): ReactElement => {
  const { connectedUser } = useConnectedUserContext()
  const [queryParams] = useSearchParams()

  const type = queryParams.get('type')
  const trainingId = queryParams.get('training_id')
  const categoryId = queryParams.get('category_id')

  const verifyRenderTrainingGroup = type && !trainingId && !categoryId
  const verifyRenderTraining = type && trainingId && !categoryId
  const verifyRenderCategory = type && trainingId && categoryId

  const [trainingsGroup, setTrainingsGroup] = useState<
    TrainingGroupType | undefined | null
  >(undefined)

  useEffect(() => {
    if (type) {
      getTrainingGroup(type, trainingId, categoryId).then((data) => {
        if (typeof data !== 'number') {
          setTrainingsGroup(data)
        } else {
          setTrainingsGroup(null)
        }
      })
    }
  }, [])

  const training = trainingsGroup?.trainings[0]
  const category = training?.categories.find((c) => c.id === categoryId)

  if (trainingsGroup === undefined) {
    return <></>
  }

  if (
    trainingsGroup &&
    (connectedUser?.idKachefa || trainingsGroup?.isPublic)
  ) {
    if (verifyRenderCategory && training && category) {
      return <Category type={type} training={training} category={category} />
    }
    if (verifyRenderTraining && training) {
      return <Training type={type} training={training} />
    }
    if (verifyRenderTrainingGroup) {
      return <TrainingGroupScreen tariningGroup={trainingsGroup} />
    }
  }

  return <NotFound />
}

export default ProtectedRoutesTraining
