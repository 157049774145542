import axios from 'axios'
import axiosRequest from '../constants/axios-request'
import { User, UserLoginRequest, UserUpdateRequest } from '../types/user'

export const loginUser = (payload: UserLoginRequest): Promise<User | number> =>
  axiosRequest
    .post('/auth/login', payload)
    .then((result) => result.data)
    .catch((error) => error.response.status)

export const signInUser = (payload: UserLoginRequest): Promise<User | number> =>
  axiosRequest
    .post('/auth/signup', payload)
    .then((result) => result.data)
    .catch((error) => error.response.status)

export const resetPasswordRequest = (payload: {
  email: string
}): Promise<number> =>
  axiosRequest
    .post('/reset', payload)
    .then((result) => result.status)
    .catch((error) => error.status)

export const resetPasswordSet = (
  resetToken: string | undefined,
  password: string
): Promise<number> =>
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/reset/new`,
      { password },
      { headers: { 'x-access-token': `${resetToken}` } }
    )
    .then((result) => result.status)
    .catch((error) => error.status)

export const updateUser = (payload: UserUpdateRequest): Promise<number> =>
  axiosRequest
    .put('/user', payload)
    .then((result) => result.status)
    .catch((error) => error.status)
