/* eslint-disable import/no-unused-modules */

import React, { ReactElement, ChangeEventHandler } from 'react'
import {
  Tickbox as StyledTick,
  StyledInput,
  StyledText,
} from './styled-components'

interface ButtonProps {
  text: string
  id: number
  onValueChange?: ChangeEventHandler<HTMLInputElement>
}

function TickBox({ text, id, onValueChange }: ButtonProps): ReactElement {
  return (
    <StyledTick>
      <StyledInput
        type="checkbox"
        id={String(id)}
        name={text}
        value={text}
        onChange={onValueChange}
      />
      <StyledText size="small">{text}</StyledText>
    </StyledTick>
  )
}

export default TickBox
