import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { find, isEmpty, map, orderBy } from 'lodash'
import parse from 'html-react-parser'
import { Description } from './styled-components'
import DashboardTemplate from '../../components/base-template/dashboard'
import { Category, Training as TrainingType } from '../../types/training'
import Button from '../../components/button'
import Text from '../../components/text'
import CategoryCardMatrix from '../../components/training/category-card-matrix'
import { useConnectedUserContext } from '../../contexts/connected-user'
import { Alert } from '../../constants/sweet-alert'
import { sendProgress } from '../../api/progress'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import ComingSoon from '../../components/coming-soon'

interface TrainingScreenProps {
  training: TrainingType
  type: string
}

const Training = ({ training, type }: TrainingScreenProps): ReactElement => {
  const { connectedUser } = useConnectedUserContext()
  const [showDescription, setShowDescription] = useState(true)
  const [loading, setLoading] = useState(false)

  const [responsiveMod, setResponsiveMod] = useState(false)
  const { width } = useWindowDimensions()
  const responsiveDimension = parseInt('768px'.replace('px', ''), 10)
  useEffect(() => {
    if (width && width < responsiveDimension) {
      setResponsiveMod(true)
    } else {
      setResponsiveMod(false)
    }
  }, [width, responsiveDimension])

  const currentProgress = find(connectedUser?.progress, {
    training: training?.id,
  })

  useEffect(() => {
    if (currentProgress) {
      setShowDescription(false)
    }
  }, [currentProgress])

  const formattedData: Category[] = useMemo(
    () =>
      map(orderBy(training?.categories, ['placement']), (category: Category) =>
        currentProgress &&
        category.placement < currentProgress?.category.placement
          ? {
              ...category,
              status: 'finished',
            }
          : category.placement === currentProgress?.category.placement
          ? {
              ...category,
              status: currentProgress?.isFinished ? 'finished' : 'pending',
            }
          : {
              ...category,
              status: null,
            }
      ),
    [training, currentProgress]
  )

  const onCategoryClick = (category: Category) => {
    if (
      (!currentProgress && category.placement === 1) ||
      (currentProgress &&
        currentProgress.isFinished &&
        category.placement === currentProgress.category.placement + 1)
    ) {
      window.location.replace(
        `/trainings?type=${type}&training_id=${training.id}&category_id=${category.id}`
      )
    } else if (
      category.status === 'finished' ||
      category.status === 'pending'
    ) {
      window.location.replace(
        `/trainings?type=${type}&training_id=${training.id}&category_id=${category.id}`
      )
    } else {
      Alert.fire({
        icon: 'warning',
        title: `عليك إنهاء الأجزاء السابقة`,
      })
    }
  }

  const startTraining = () => {
    if (!currentProgress) {
      setLoading(true)
      sendProgress({
        isFinished: 'false',
        training: training.id,
        category: training?.categories[0].id,
        partPlacement: 1,
      }).then(() => {
        setShowDescription(false)
        setLoading(false)
      })
    }
  }

  if (training) {
    if (!isEmpty(training.categories)) {
      return (
        <DashboardTemplate active={type} title={training.title}>
          {showDescription ? (
            <>
              <Description> {parse(training.description)} </Description>
              <Button
                size={responsiveMod ? '90%' : '25rem'}
                color="darkRed"
                onClick={() => startTraining()}
                loading={loading}
              >
                <Text>ابدأ الاختبار</Text>
              </Button>
            </>
          ) : (
            <CategoryCardMatrix
              onClick={(category) => onCategoryClick(category)}
              categories={formattedData}
            />
          )}
        </DashboardTemplate>
      )
    }
    return (
      <DashboardTemplate>
        <ComingSoon />
      </DashboardTemplate>
    )
  }
  return <></>
}

export default Training
