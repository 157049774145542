/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { DefaultTheme } from 'styled-components'

import {
  EmailSection,
  TextSection,
  StyledLink,
  StyledImg,
} from '../styled-components'
import Text from '../../../text'
import Button from '../../../button'
import ErrorEmailVerif from '../../../../assets/images/error-email-verification.png'

export interface Props {
  color: keyof DefaultTheme['colors']
}

function ErrorEmailVerification({ color }: Props): ReactElement {
  return (
    <EmailSection>
      <StyledImg src={ErrorEmailVerif} color={color} />
      <TextSection>
        <Text size="medium" center>
          حدث خطأ، يمكنك طلب تفعيل الحساب مرة أخرى
        </Text>
      </TextSection>
      <StyledLink to="/">
        <Button size="100%" color="darkRed">
          <Text weight="bold">تسجيل الدخول</Text>
        </Button>
      </StyledLink>
    </EmailSection>
  )
}

export default ErrorEmailVerification
