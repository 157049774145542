import styled, { DefaultTheme } from 'styled-components'

export const Button = styled.button.attrs(
  (props: { size: string; color: keyof DefaultTheme['colors'] }) => props
)`
  background-color: ${(props) => props.theme.colors[props.color]};
  border-radius: 20px;
  border: 1px solid
    ${(props) => (props.color === 'white' ? props.theme.colors.red : null)};
  color: ${(props) =>
    props.color === 'white'
      ? props.theme.colors.red
      : props.theme.colors.white};
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  width: ${(props) => (props.size ? props.size : null)} !important;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    width: 100%;
  }
`
