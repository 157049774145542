/* eslint-disable react/jsx-props-no-spreading ,react/destructuring-assignment,  @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { SelectItems } from '../../../types/select'
import { Selection } from './styled-components'

interface SelectProps {
  options: SelectItems[]
  onChange?: (option: SelectItems) => void
}

function Select({ options, onChange }: SelectProps): ReactElement {
  return (
    <Selection
      options={options}
      classNamePrefix="Select"
      defaultValue={null}
      placeholder=" إختر إجابة..."
      onChange={onChange as any}
      isSearchable={false}
      isRtl
    />
  )
}

export default Select
