import React, { ReactElement, useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import DashboardTemplate from '../../components/base-template/dashboard'
import profileEdit from '../../assets/images/contact-us.png'
import {
  StyledImage,
  UpperDiv,
  FormDiv,
  Container,
  StyledButtons,
  Info,
  SingleInfo,
} from './styled-components'
import Inputfield from '../../components/input/input-field'
import Button from '../../components/button'
import Text from '../../components/text'
import InputArea from '../../components/input/input-area'
import { Alert } from '../../constants/sweet-alert'
import { sendEmail } from '../../api/contact-us'
import Icon from '../../components/icon'

const ContactUs = (): ReactElement => {
  const [subject, setSubject] = useState('')
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const sendMail = () => {
    if (subject !== '' && content !== '') {
      setLoading(true)
      sendEmail({ subject, text: content }).then((res) => {
        if (res !== 200) {
          Alert.fire({
            icon: 'warning',
            title: `حصل خطأ ,الرجاء المحاولة لاحقا `,
          })
        } else {
          Alert.fire({
            icon: 'success',
            title: `تم إرسال الطلب بنجاح`,
          })
          setSubject('')
          setContent('')
        }
        setLoading(false)
      })
    } else {
      Alert.fire({
        icon: 'warning',
        title: `عليك ملأ جميع الفراغات`,
      })
    }
  }
  return (
    <DashboardTemplate active="contact-us" title="إتصل بنا">
      <Container>
        <Info>
          <SingleInfo>
            <Text size="small"> 501 790 71 216+</Text>
            <Icon
              name="ph:phone-call-light"
              color="black"
              width={30}
              height={30}
            />
          </SingleInfo>
          <SingleInfo>
            <Text size="small">شارع يوغرطة ,تونس ,تونس</Text>
            <Icon
              name="ph:map-pin-line-light"
              color="black"
              width={30}
              height={30}
            />
          </SingleInfo>
        </Info>

        <UpperDiv>
          <StyledImage src={profileEdit} />
          <FormDiv>
            <Inputfield
              onValueChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSubject(event.target.value)
              }}
              labelName="الموضوع"
              value={subject}
            ></Inputfield>
            <InputArea
              onValueChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                setContent(event.target.value)
              }}
              value={content}
              labelName="نص الموضوع"
            ></InputArea>
          </FormDiv>
        </UpperDiv>
        <StyledButtons>
          <Button loading={loading} onClick={sendMail} color="darkRed">
            <Text>إرسال</Text>
          </Button>
          <Button color="white" onClick={() => navigate('/dashboard')}>
            <Text>العودة الى الرئيسية</Text>
          </Button>
        </StyledButtons>
      </Container>
    </DashboardTemplate>
  )
}

export default ContactUs
