import styled from 'styled-components'

export const Signin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`
export const InputSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  @media (max-width: ${(props) => props.theme.responsive.laptop}) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`
export const IdKachefaSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  direction: rtl;
`

export const IdKachefaInput = styled.div`
  display: flex;
  gap: 0.75rem;
`

export const SelectInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
`
