import styled, { DefaultTheme } from 'styled-components'

export const DownloadText = styled.div.attrs(
  (props: { color: keyof DefaultTheme['colors'] }) => props
)`
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 70%;
  color: ${(props) => props.theme.colors[props.color]};
  border: 2px solid ${(props) => props.theme.colors[props.color]};

  background-color: ${(props) =>
    props.theme.backgroundColors[
      props.color as keyof DefaultTheme['backgroundColors']
    ]};
  border-bottom: 6px solid;

  @media (max-width: ${(props) => props.theme.responsive.laptopL}) {
    width: 80%;
  }
  @media (max-width: ${(props) => props.theme.responsive.mobileL}) {
    width: 100%;
  }
`
