import React, { ReactElement, ChangeEvent } from 'react'
import { Part, Question } from '../../../types/training'
import {
  Questions as StyledQuestions,
  QuestionTick,
  QuestionTextTickBox,
} from '../styled-components'

import TickBox from '../../input/tick-box'
import { SelectItems } from '../../../types/select'

interface QuizTickProps {
  part: Part | undefined
  onValueChange: (selectedItem: SelectItems, question: Question) => void
}

function QuizTick({ part, onValueChange }: QuizTickProps): ReactElement {
  return (
    <StyledQuestions>
      {part?.questions.map((question) => (
        <QuestionTick key={question.id}>
          <QuestionTextTickBox lineHeight={32}>
            {question.text}
          </QuestionTextTickBox>
          {question.answers.map((answer, index) => (
            <TickBox
              onValueChange={(event: ChangeEvent<HTMLInputElement>) => {
                const selectedItem = {
                  id: index,
                  value: event.target.value,
                  label: event.target.value,
                }
                onValueChange(selectedItem, question)
              }}
              key={index}
              id={index}
              text={answer}
            />
          ))}
        </QuestionTick>
      ))}
    </StyledQuestions>
  )
}

export default QuizTick
