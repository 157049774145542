/* eslint-disable react/jsx-props-no-spreading ,react/destructuring-assignment,  @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import moment from 'moment'
import 'moment/locale/ar-tn'
import {
  Document,
  Page,
  Font,
  StyleSheet,
  Image,
  Text,
} from '@react-pdf/renderer'
import certif from '../../assets/images/certif.png'
import { Certification } from '../../types/certification'
import { User } from '../../types/user'
import locations from '../../constants/pdf-generator/locations'

// Create styles
Font.register({
  family: 'ubuntu',
  fonts: [
    {
      src: 'https://res.cloudinary.com/enigma-dev/raw/upload/v1654439777/L1/ElMessiri-SemiBold_czvriz.ttf',
    },
  ],
})
const fontSize = 20
const infoFontSize = fontSize + 2
const styles = StyleSheet.create({
  text: {
    position: 'absolute',
    top: 100,
    fontFamily: 'ubuntu',
    fontSize: 40,
    alignSelf: 'center',
  },
  Coretext: {
    position: 'absolute',
    top: 180,
    fontFamily: 'ubuntu',
    fontSize,
    alignSelf: 'center',
  },
  Coretext2: {
    position: 'absolute',
    top: 360,
    fontFamily: 'ubuntu',
    fontSize,
    alignSelf: 'center',
  },
  Coretext3: {
    position: 'absolute',
    top: 400,
    fontFamily: 'ubuntu',
    fontSize,
    alignSelf: 'center',
  },
  trainingTitle: {
    color: 'red',
    position: 'absolute',
    top: 320,
    fontFamily: 'ubuntu',
    fontSize,
    alignSelf: 'center',
  },
  Signature1: {
    position: 'absolute',
    top: 450,
    right: 100,
    fontFamily: 'ubuntu',
    fontSize: fontSize - 7,
    alignSelf: 'center',
    zIndex: 100,
  },
  SignatureName: {
    position: 'absolute',
    top: 470,
    right: 140,
    fontFamily: 'ubuntu',
    fontSize: fontSize - 7,
    alignSelf: 'center',
    zIndex: 100,
    color: 'red',
  },
  Signature2: {
    position: 'absolute',
    top: 400,
    left: 100,
    fontFamily: 'ubuntu',
    fontSize,
    alignSelf: 'center',
  },
  CoretextInfoName: {
    position: 'absolute',
    top: 220,
    fontFamily: 'ubuntu',
    fontSize: 15,
    alignSelf: 'center',
  },
  CoretextInfoColoredName: {
    fontFamily: 'ubuntu',
    fontSize: infoFontSize,
    color: 'red',
  },
  CoretextInfoPlace: {
    position: 'absolute',
    top: 270,
    fontFamily: 'ubuntu',
    fontSize,
    alignSelf: 'center',
  },
  CoretextInfoColoredPlace: {
    fontFamily: 'ubuntu',
    fontSize: infoFontSize,
    color: 'red',
  },
  page: {
    position: 'relative',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  certifId: {
    position: 'absolute',
    top: 360,
    left: -65,
    transform: 'rotate(-90deg)',
    fontSize: 10,
    fontFamily: 'ubuntu',
    letterSpacing: 2,
  },
})

interface PdfProps {
  certification: Certification | undefined
  connectedUser: Omit<User, 'certifications'> | undefined
}
// Create Document Component
// set NODE_OPTIONS=--openssl-legacy-provider
const MyDocument = ({
  certification,
  connectedUser,
}: PdfProps): ReactElement => {
  const certifDate = moment(certification?.date)
  const year = certifDate.format('YYYY')
  const [month, day, weekDay] = certifDate
    .format('LLLL')
    .slice(0, -10)
    .split(' ')
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Text style={styles.text}>شهادة مشاركة</Text>
        <Text style={styles.Coretext}>
          يشهد رئيس اللجنة الوطنية لتنمية القيادات بالكشافة التونسية أن
        </Text>
        <Text style={styles.CoretextInfoName}>
          <Text style={styles.CoretextInfoColoredPlace}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {connectedUser?.location.toString()
              ? locations[connectedUser?.location?.toString()]
              : 'الكشافة'}
          </Text>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;بجهة :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Text style={styles.CoretextInfoColoredName}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {connectedUser
              ? `${connectedUser.firstName} ${connectedUser.lastName}`
              : ''}
          </Text>
          القائد)ة( :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Text>

        <Text style={styles.trainingTitle}>
          &nbsp;&nbsp;{certification?.training.title}&nbsp;&nbsp;
        </Text>
        <Text style={styles.Coretext2}>
          <Text style={styles.Coretext3}> و بعد إتمام جميع متطلباتها </Text>
          <Text style={styles.CoretextInfoColoredPlace}>
            &nbsp;&nbsp;&nbsp;
            {year}&nbsp;
            {weekDay}&nbsp;
            {day}&nbsp;
            {month}&nbsp;
          </Text>
          و ذلك بتاريخ&nbsp;&nbsp;&nbsp;
        </Text>
        <Text style={styles.CoretextInfoPlace}>
          <Text style={styles.CoretextInfoColoredPlace}>
            &nbsp;&nbsp;{certification?.training.code}
            &nbsp;&nbsp;
          </Text>
          قد إجتاز بنجاح الوحدة التأهيلية عن بعد
        </Text>
        <Text style={styles.Signature1}>
          رئيس اللجنة الوطنية لتنمية القيادات
        </Text>
        <Text style={styles.SignatureName}>ق / سامي بن عمار</Text>
        <Text style={styles.certifId}>{certification?.id}</Text>

        <Image source={certif}></Image>
      </Page>
    </Document>
  )
}

export default MyDocument
