/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-undef */
import { useState, useEffect } from 'react'

interface DimensionType {
  width: number | null
  height: number | null
}

export default function useWindowDimensions(): DimensionType {
  const hasWindow = typeof window !== 'undefined'

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null
    const height = hasWindow ? window.innerHeight : null
    return {
      width,
      height,
    }
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  function handleResize() {
    setWindowDimensions(getWindowDimensions())
  }

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [getWindowDimensions, hasWindow])

  return windowDimensions
}
