import styled, { DefaultTheme } from 'styled-components'

export const UnderlineText = styled.div.attrs(
  (props: { color: keyof DefaultTheme['colors'] }) => props
)`
  color: ${(props) =>
    props.color === 'white' ? props.theme.colors.darkGrey : null};
  border-bottom: 3px solid ${(props) => props.theme.colors[props.color]};
  cursor: pointer;
  padding: 2px 0;
  width: fit-content;
`
