/* eslint-disable import/no-unused-modules */

import React, { ChangeEventHandler, ReactElement, useState } from 'react'
import { Inputbox, InputField, ShowhidePassword } from './styled-components'
import Text from '../../text'
import Icon from '../../icon'

interface InputfieldProps {
  onValueChange: ChangeEventHandler<HTMLInputElement>
  value: string | number | string[] | undefined
  labelName: string
  disabled?: boolean
  type?: string
  typeFocus?: string
  onKeyPress?: () => void
}

function Inputfield({
  onValueChange,
  value,
  labelName,
  type,
  disabled,
  typeFocus,
  onKeyPress,
}: InputfieldProps): ReactElement {
  const [typeInput, setTypeInput] = useState<string | undefined>('')
  const [showPassword, setShowPassword] = useState(false)
  return (
    <InputField>
      <Text size="small">{labelName}</Text>
      <Inputbox
        type={showPassword ? 'text' : type || typeInput}
        placeholder={labelName}
        onChange={onValueChange}
        value={value}
        disabled={!!disabled}
        onFocus={() => {
          setTypeInput(typeFocus)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && onKeyPress) {
            onKeyPress()
          }
        }}
      />
      {(typeFocus || type) === 'password' ? (
        <ShowhidePassword onClick={() => setShowPassword(!showPassword)}>
          <Icon
            color="darkGrey"
            name={showPassword ? 'bx:hide' : 'bx:show-alt'}
          />
        </ShowhidePassword>
      ) : null}
    </InputField>
  )
}

export default Inputfield
