/* eslint-disable import/no-unused-modules */

import React, { ReactElement } from 'react'
import { Part, Question } from '../../../types/training'
import {
  Questions as StyledQuestions,
  Question as StyledQuestion,
  QuestionSelect,
  QuestionText,
} from '../styled-components'
import { SelectItems } from '../../../types/select'
import Select from '../../input/select'

interface QuizSelectProps {
  part: Part | undefined
  onChange: (selectedItem: SelectItems, question: Question) => void
}

function QuizSelect({ part, onChange }: QuizSelectProps): ReactElement {
  return (
    <StyledQuestions>
      {part?.questions.map((question) => (
        <StyledQuestion key={question.id}>
          <QuestionText lineHeight={32}>{question.text}</QuestionText>
          <QuestionSelect>
            <Select
              onChange={(selectedItem) => {
                onChange(selectedItem, question)
              }}
              options={
                question.answers.map((answer) => ({
                  id: question.answers.indexOf(answer),
                  label: answer,
                  value: answer,
                })) as []
              }
            />
          </QuestionSelect>
        </StyledQuestion>
      ))}
    </StyledQuestions>
  )
}

export default QuizSelect
