/* eslint-disable import/no-unused-modules */

import React, { ReactElement, useEffect, useState } from 'react'
import { map } from 'lodash'
import Text from '../../../text'
import { Menu as StyledMenu, Item, StyledA } from './styled-components'
import { useConnectedUserContext } from '../../../../contexts/connected-user'
import { getTrainingGroups } from '../../../../api/training-group'
import { TrainingGroup } from '../../../../types/training'

export interface MenuProps {
  active?: string
}
function Menu({ active }: MenuProps): ReactElement {
  const { connectedUser } = useConnectedUserContext()
  const [trainingsGroups, setTrainingsGroups] = useState<TrainingGroup[]>()

  useEffect(() => {
    getTrainingGroups().then((data) => {
      if (typeof data !== 'number') {
        setTrainingsGroups(data)
      }
    })
  }, [])

  return (
    <StyledMenu>
      <StyledA href="/">
        <Item isActive={active === 'home'}>
          <Text size="small" color="white" weight="bold">
            الرئيسية
          </Text>
        </Item>
      </StyledA>
      <StyledA href="/certifications">
        <Item isActive={active === 'certifications'}>
          <Text size="small" color="white" weight="bold">
            الشهائد المتحصل عليها
          </Text>
        </Item>
      </StyledA>

      {map(trainingsGroups, (item) =>
        connectedUser?.idKachefa || item.isPublic ? (
          <StyledA key={item.id} href={`/trainings?type=${item.name}`}>
            <Item isActive={active === item.name}>
              <Text size="small" color="white" weight="bold">
                {item.title}
              </Text>
            </Item>
          </StyledA>
        ) : null
      )}

      <StyledA href="/contact-us">
        <Item isActive={active === 'contact-us'}>
          <Text size="small" color="white" weight="bold">
            إتصل بنا
          </Text>
        </Item>
      </StyledA>
    </StyledMenu>
  )
}

export default Menu
