import React, { ReactElement } from 'react'
import { isEmpty } from 'lodash'
import DashboardTemplate from '../../components/base-template/dashboard'
import CertificationCardMatrix from '../../components/certification-card-matrix'
import { useConnectedUserContext } from '../../contexts/connected-user'
import certificationempty from '../../assets/images/certification-empty.png'
import { StyledImage } from './styled-components'
import Text from '../../components/text'

const Certificaions = (): ReactElement => {
  const { connectedUser } = useConnectedUserContext()
  return (
    <>
      <DashboardTemplate title="الشهائد المتحصل عليها" active="certifications">
        {!isEmpty(connectedUser?.certifications) ? (
          <CertificationCardMatrix />
        ) : (
          <>
            <StyledImage src={certificationempty} />
            <Text size="big" center>
              لم تتحصل على شهادة بعد
            </Text>
          </>
        )}
      </DashboardTemplate>
    </>
  )
}
export default Certificaions
